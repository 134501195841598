import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, from, Observer } from 'rxjs';
import { map, startWith, toArray, filter } from 'rxjs/operators';
import { ConnectionService } from 'src/app/services/connection.service';
import * as moment from "moment";
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  logs$: Observable<any[]>;
  filter = new FormControl('');
  users: Array<object> = [];
  allLogs: Array<object> = [];

  constructor(private conn: ConnectionService, private settingsService: SettingsService) { 
    
    if (this.users && !this.users.length) {
      this.conn
          .getStaff()
          .subscribe((res: any) => {
            this.users = res.data
          })
    }
    
    if (!this.allLogs.length) {
      this.conn
          .getLogs()
          .subscribe((logs: any) => {
            const newArr = from(logs.data).pipe(
              map((log: any) => ({
                  ...log,
                  createdAtFormatted: moment.utc(log.createdAt).format('LLLL'),
                  user_name: this.resolveUserName(log)
                })
              ),
              toArray()
            );
  
            newArr.subscribe(result => {
  
              const sortedArr = result.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
              this.allLogs = sortedArr;
              
              this.logs$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
                observer.next(sortedArr);
              })
            })
          })
    } else {
      this.logs$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        observer.next(this.allLogs);
      })
    }

    
  }

  ngOnInit() {
    
  }

  search() {
    this.settingsService.searchTable('search', 'searchable');
  }

  sort() {
    this.settingsService.sortTable('searchable');
  }

  resolveUserName(log) {
    let user;
    from(this.users).pipe(filter((user: any) => user.id == log.uid), map((selected: any) => selected.name)).subscribe(res => user = res);
    return user;
  }

}
