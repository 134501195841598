import { Component, OnInit, ElementRef } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Globals } from 'src/app/globals.component';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  path;
  public user?: any;
  public showSettings = false;
  public menu = [
    {icon: 'account_circle', label: 'Profile', link: '/profile'},
  ];

  constructor(private settingsService: SettingsService,
              private global: Globals) { }

  ngOnInit() {
    this.settingsService.userInfo.subscribe(res => {
      this.user = res;
    });
    this.path = this.global.path;
  }

  logout() {
    this.settingsService.logout(this.user);
  }

  fullScreen() {
    const elem: any = document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen ||
                              elem.webkitRequestFullScreen ||
                              elem.mozRequestFullscreen ||
                              elem.msRequestFullscreen;
    if ( methodToBeInvoked ) {
      methodToBeInvoked.call(elem);
    }
  }


  settings(el: ElementRef) {
    if (this.showSettings) {
      this.showSettings = false;
    } else {
      this.showSettings = true;
    }
  }

}
