import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() public user: any = {};

  constructor(private settingsService: SettingsService) {}

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
      if (res) {
        this.settingsService.spinner(false, 1000);
      }
    });
  }

  logout() {
    this.settingsService.logout(this.user);
  }


}
