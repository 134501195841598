import { Injectable } from '@angular/core';
import { toast } from '../../node_modules/materialize-css';
import Swal from '../../node_modules/sweetalert2/dist/sweetalert2.js';



@Injectable({
  providedIn: 'root'
})
export class Globals {

    //Bing Key
    bingKey: 'ApXRm4GX9eA6kpY66jjcvyeEDL0s_xkFbxgbPXQ75ISvpk-niTcYK17ulKpXjiyz';

    // path = window.location.pathname.slice(0,4);
    path = '';

    // AWS
    S3_BUCKET_ACCESS_KEY_ID = 'AKIA2MK7RQWMOFSXTNOO';
    S3_BUCKET_SECRET_ACCESS_KEY = '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';
    S3_USER = 'bth-s3';


    /**
     * documentation: https://www.npmjs.com/package/ngx-editor
     */
    editorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '200px',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        imageEndPoint: '',
        toolbar: [
            [
                'bold',
                'italic',
                'underline',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'cut',
                'copy',
                'delete',
                'removeFormat',
                'undo',
                'redo',
                'link',
                'unlink',
                'video'
            ],
         ]
    };

    /*
      DATEPICKEROPTION - Call on component
      onClose: () => alert('Close has been invoked.'),
      onOpen: () => alert('Open has been invoked.'),
    */
    datePickerOptions = {
      clear: 'Clear', // Clear button text
      close: 'Ok',    // Ok button text
      today: 'Today', // Today button text
      closeOnClear: true,
      closeOnSelect: false,
      format: 'dddd, dd mmm, yyyy', // Visible date format (defaulted to formatSubmit if provided otherwise 'd mmmm, yyyy')
      formatSubmit: 'yyyy-mm-dd',   // Return value format (used to set/get value)
      selectMonths: true, // Creates a dropdown to control month
      selectYears: 10,    // Creates a dropdown of 10 years to control year,
    };

    /**
     * Documentation: https://materializecss.com/toasts.html
     * @param text 
     * @param duration 
     */
    toastFunc(text: string, duration: number) {
        toast({
          html: text,
          displayLength: duration,
          inDuration: 300,
          outDuration: 375,
        });
    }

    /**
     * Documentation: https://sweetalert2.github.io/#examples
     * @param data 
     * @param method (title: string, text: string, type: string [success, error, warning, info, question], cancelButton: boolean, confirmText: string)
     * @param callback 
     */
    sweetAlert(data) {

      return Swal.fire({
        title: data.title,
        text: data.text,
        type: data.type,
        showCancelButton: data.cancelButton,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: data.confirmText
      });
    }

    sweetToast(sweetType, sweetTitle) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });

      Toast.fire({
        type: sweetType,
        title: sweetTitle
      });
    }

    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && ((charCode < 48 && charCode !== 46) || (charCode > 57 && (charCode !== 110 && charCode !== 190)))) {
        this.sweetToast('warning', 'Only numbers are accepted');
        return false;
      }
      return true;
    }

}
