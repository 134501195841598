import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { from } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { Client } from 'src/app/classes/client';
import { Staff } from 'src/app/classes/staff';
import { Globals } from 'src/app/globals.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { SettingsService } from 'src/app/services/settings.service';
import Swal from '../../../../node_modules/sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {
  formTask = false;
  user;
  todoItems = [];
  inProgressItems = [];
  doneItems = [];
  allItems = [];
  task: any = {};
  customers;
  services;
  staffs;
  isSearchingClient = false;
  idToSearch = 0;
  searchInput: any;
  clientsList: any[];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '80px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
            'redo',
            'link',
            'unlink',
            'video'
        ],
    ]
  };
  typeBtn = 'add';
  phoneClientSelectedTask = '';
  emailClientSelectedTask = '';
  
  constructor(
    private settingsService: SettingsService,
    private connService: ConnectionService,
    private dashboard: DashboardComponent,
    private global: Globals
  ) {}

  ngOnInit() {
    this.settingsService.checkLogin();
    this.user = this.dashboard.user;
    this.initiate();
  }

  initiate() {
    this.getCustomerList();
    this.getServiceList();
    this.getStaffList();
    this.getTasks();
  }

  getTasks() {
    this.connService
        .getTasks()
        .subscribe((res: any) => {
          // console.log(res.data);
          const data = res.data;
          this.allItems = res.data;
          for (const iterator of data) {
            this.orderingTasks(iterator);
          }
        });
  }

  orderingTasks(item) {
    if(item.is_active === 'yes') {
      if (item.status === 'todo') {
        this.todoItems.push(item);
      } else if (item.status === 'progress') {
        this.inProgressItems.push(item);
      } else if (item.status === 'done') {
        this.doneItems.push(item);
      } else {
        console.log(`Item #${item.id} has no status defined`);
      }
    }
  }

  filterTasks(type, action?) {
    if (action === 'reset') {
      this.todoItems = [];
      this.inProgressItems = [];
      this.doneItems = [];
    } else {
      if (type === 'todo') {
        this.todoItems = [];
      }
      if (type === 'progress') {
        this.inProgressItems = [];
      }
      if (type === 'done') {
        this.doneItems = [];
      }
    }
    for (const iterator of this.allItems) {
      if (action === 'reset') {
        this.orderingTasks(iterator);
      } else {
        if (iterator.staff_id == (this.user.uid || this.user.id) && iterator.is_active == 'yes') {
          if (iterator.status == type) {
            if (type === 'todo') {
              this.todoItems.push(iterator);
            } else if (type === 'progress') {
              this.inProgressItems.push(iterator);
            } else if (type === 'done') {
              this.doneItems.push(iterator);
            } else {
            }
          }
        }
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, container?) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      const el: any = event.container.data[event.currentIndex];
      el.status = container;
      this.connService
          .updateData(el, el.id, 'erp_task')
          .subscribe((res: any) => {
            if (res.data && res.data.affectedRows && res.data.changedRows) {
              this.formTask = false;
              this.global.sweetToast('success', `Task #${el.id} successfully updated`);
              this.connService.log({
                uid: this.user.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.user.username} has successfully changed Task #${el.id} successfully`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
            } else {
              this.global.sweetToast('error', `Task #${el.id} was not updated`);
            }
          });
    }
  }

  onEntered(enter) {
    // console.log('ee', enter);
  }

  getServiceList() {
    this.connService
        .getServices()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(filter((service: any) => service.active == 1), toArray())
          .subscribe(servicesArr => this.services = servicesArr.sort());
        });
  }

  getStaffList() {
    this.connService
        .getStaff()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((staffs: any) => ({
              id: staffs.id,
              name: staffs.name,
              access_status: staffs.access_status,
              level: this.settingsService.convertLevel(staffs.access_level),
            })),
            filter((staff: any) => staff.access_status == 'active'),
            toArray()
            )
          .subscribe(staffArr => this.staffs = staffArr.sort());
        });
  }

  getCustomerList() {
    this.connService
        .getCustomers()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((client: Client) => ({
              id: client.id,
              name: client.name,
              access_status: client.access_status,
              phone_primary: client.phone_primary,
              phone_secondary: client.phone_secondary,
              email: client.email
            })),
            filter((clientObj: any) => clientObj.access_status == 'active'),
            toArray()
          )
          .subscribe(clientArr => {
              const newArr = clientArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.customers = newArr;
          });
        });
  }

  setIdToSearch(id, name) {
    this.task.client_id = id;
    this.idToSearch = id;
    this.searchInput = name;
    this.isSearchingClient = false;
  }

  getSearchClient(event) {
    this.clientsList = [];
    const input = event.target.value;
    this.idToSearch = 0;
    if (input) {
      this.connService
        .searchClient(input, 'name')
        .subscribe((clients: any) => {
          from(clients.data)
          .pipe(
              map((client: Client) => ({
                name: client.name,
                id: client.id,
                access_status: client.access_status
              })),
              filter((cl: Client) => cl.access_status == 'active'),
              toArray()
            )
            .subscribe((clientsList) => {
              if (clientsList.length) {
                this.clientsList = clientsList.sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.isSearchingClient = true;
              } else {
                this.isSearchingClient = false;
              }
            });
      });
    }
  }

  openForm(action?, item?) {
    window.scroll(0,0);
    this.task = {};
    this.searchInput = '';
    this.phoneClientSelectedTask = '';
    this.emailClientSelectedTask = '';
    if (action) {
      this.typeBtn = 'update';
      this.formTask = true;
      this.task = item;
        
      from(this.customers)
          .pipe(
            filter((clientObj: any) => clientObj.id == item.client_id),
            map((client: Client) => ({
              id: client.id,
              name: client.name,
              access_status: client.access_status,
              phone_primary: client.phone_primary,
              phone_secondary: client.phone_secondary,
              email: client.email
            }))
          )
          .subscribe(clientResp => {
            console.log(clientResp);
            
            this.searchInput = clientResp.name;
            if (clientResp.phone_primary) {
              this.phoneClientSelectedTask = clientResp.phone_primary;
            } else if (clientResp.phone_secondary) {
              this.phoneClientSelectedTask = clientResp.phone_secondary;
            } else {
              console.log("Client has no phone added");
              this.phoneClientSelectedTask = '';
            }
            if (clientResp.email) {
              this.emailClientSelectedTask = clientResp.email;
            }
          });

      from(this.staffs)
      .pipe(
        filter((staff: Staff) => staff.id == item.user_id),
        map((selected: any) => selected.name)
      ).subscribe((staffUser: Staff) => {
        this.task.username = staffUser;
      });
      
    } else {
      if (this.formTask) {
        this.formTask = false;
        this.task = {};
        this.phoneClientSelectedTask = '';
        this.typeBtn = 'add';
        this.searchInput = '';
      } else {
        this.formTask = true;
      }
    }
  }

  setTypeBtn(data) {
    if (data === 'add') {
      this.typeBtn = data;
      this.searchInput = '';
      this.task = {};
    }
  }

  add() {

    const task = this.task;
    task.created_at = this.settingsService.currentDate();
    if (this.user.uid) {
      task.user_id = this.user.uid;
    } else {
      task.user_id = this.user.id;
    }

    if (task.username) {
      delete task.username 
    }

    // console.log(task);
    this.connService.addData(task, 'erp_task').subscribe((res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.insertId) {
          this.connService.log({
            uid: task.user_id,
            createdAt: this.settingsService.currentDate(),
            content: `User ${this.user.username} has successfully added Record ID #${res.data.insertId}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
        } else {
            this.connService.log({
              uid: task.user_id,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully added a record`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
        }
        this.getTasks();
        this.setTypeBtn('add');
      } else {
        this.global.sweetToast('error', 'Record was not added');
      }
    });
  }

  update() {
    const id = this.task.id;
    const task: any = this.task;
    
    delete task.id;
    delete task.username;
    
    if (this.task.client_id != task.client_id) {
      task.client_id = this.idToSearch;
    }

    this.connService.updateData(task, id, 'erp_task').subscribe(async (res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.formTask = false;
          this.global.sweetToast('success', `Task ${id} was successfully updated`);
          this.todoItems = [];
          this.inProgressItems = [];
          this.doneItems = [];
          this.getTasks();
          this.openForm();
          this.connService.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `Task was successfully updated by ${this.user.name}. Record ID #${id}`
          }).subscribe(log => {
            // console.log('Log added successfully');
          });
        } else {
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.global.sweetToast('error', 'Sorry! Task was not updated. Please try again');
      }
    });
  }
  

  delete() {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    const task = {
      is_active: "no"
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        const id = this.task.id;
        this.connService.deleteData(id, 'erp_task').subscribe(async (res: any) => {
          if (res.data && (res.data.affectedRows > 0 || res.data.changedRows > 0)) {
            this.global.sweetToast('success', `Task ${this.task.id} was successfully deleted`);
            this.todoItems = [];
            this.inProgressItems = [];
            this.doneItems = [];
            this.getTasks();
            this.setTypeBtn('add');
            this.task = {};
            this.typeBtn = 'add';
            this.searchInput = '';
            this.connService.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `Task was successfully deleted by ${this.user.name}. Task ID #${this.task.id}`
            }).subscribe(log => {
              // console.log('Log added successfully');
            });
            // this.initiate();
           
          } else {
            this.global.sweetToast('error', 'Sorry! transaction was not deleted. Please try again');
          }
        });
        // this.connService.updateData(task, this.task.id, 'erp_task').subscribe(async (res: any) => {
        //   if (res.data && res.data.affectedRows > 0) {
        //     if (res.data.changedRows > 0) {
        //       this.global.sweetToast('success', `Task ${this.task.id} was successfully inactivated`);
        //       this.todoItems = [];
        //       this.inProgressItems = [];
        //       this.doneItems = [];
        //       this.getTasks();
        //       this.setTypeBtn('add');
        //       this.task = {};
        //       this.typeBtn = 'add';
        //       this.searchInput = '';
        //       this.connService.log({
        //         uid: this.user.uid,
        //         createdAt: this.settingsService.currentDate(),
        //         content: `Task was successfully inactivated by ${this.user.name}. Task ID #${this.task.id}`
        //       }).subscribe(log => {
        //         // console.log('Log added successfully');
        //       });
        //     } else {
        //       this.global.sweetToast('warning', 'No changes was detected');
        //     }
        //   } else {
        //     this.global.sweetToast('error', 'Sorry! Task was not deleted. Please try again');

        //   }
        // });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });
  }

  createRecord() {
    Swal.fire({
      title: 'Enter the record description',
      input: 'textarea',
      inputValue: this.task.description,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        'aria-label': 'Type your description here'
      },
      inputPlaceholder: 'Enter record description',
      showCancelButton: true,
      confirmButtonText: 'Done',
      showLoaderOnConfirm: true,
      preConfirm: (description) => {
        if (description != this.task.description) {
          return description;
        } else {
          return this.task.description;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
  
      const record = {
        id: null,
        client_id: this.task.client_id,
        service_id: this.task.service_id,
        description: result.value,
        created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        active: '1',
        owner_id: this.task.staff_id,
        user_id: this.task.user_id,
        task_id: this.task.id,
        event_id: null
      }
      
      if (!record.description) {
        // console.log(this.user);
        
        Swal.fire({
          icon: 'error',
          title: `Oops...`,
          text: 'Description is missing! Please add a description.',
          // footer: '<a href>Why do I have this issue?</a>,'
          showCloseButton: true,
        })
      } else {
        this.connService
            .addDataValues(record,'erp_record')
            .subscribe((res: any) => {
              if (res.data && res.data.affectedRows > 0) {
                this.global.sweetToast('success', 'Record was successfully added');
                if (res.data.insertId) {
                  this.connService.log({
                    uid: (this.user.id ? this.user.id : this.user.uid),
                    createdAt: this.settingsService.currentDate(),
                    content: `User ${this.user.username} has successfully added record ID #${res.data.insertId}`
                  }).subscribe(log => {
                    // console.log('Login successfully');
                  });
                } else {
                    this.connService.log({
                      uid: (this.user.id ? this.user.id : this.user.uid),
                      createdAt: this.settingsService.currentDate(),
                      content: `User ${this.user.username} has successfully added a record`
                    }).subscribe(log => {
                      // console.log('Login successfully');
                    });
                }
              } else {
                this.global.sweetToast('error', 'Record was not added');
              }
            });
      }
    })
  }

  createFinance() {
    // console.log(this.task);
    Swal.fire({
      icon: 'warning',
      title: 'Finance',
      text: 'Convert task into finance activity',
      html:
      `
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Amount</span>
          </div>
          <input type="number" id="addFinanceAmount" class="form-control b4-custom-input" aria-label="Default" aria-describedby="inputGroup-sizing-default" placeholder="Amount here...">
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Type</label>
          </div>
          <select class="custom-select" id="addFinanceType">
            <option value="profit">Profit</option>
            <option value="deficit">Deficit</option>
          </select>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Payment Method</label>
          </div>
          <select class="custom-select" id="addFinancePayment">
            <option value="1">Cash</option>
            <option value="2">Card</option>
            <option value="3">Check</option>
            <option value="4">Other</option>
          </select>
        </div>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Status</label>
          </div>
          <select class="custom-select" id="addFinanceStatus">
            <option value="processed">Processed</option>
            <option value="pending">Pending</option>
          </select>
        </div>
        <div class="col-12 no-padding mt-3">
          <span class="note text-left">Description</span>
          <textarea rows="4" id="addFinanceDescription" placeholder="Description Here..."></textarea>
        </div>
      `,
      focusConfirm: false,
      preConfirm: () => {
        const input1: any = document.getElementById('addFinanceAmount');
        const input2: any = document.getElementById('addFinanceType');
        const input3: any = document.getElementById('addFinancePayment');
        const input4: any = document.getElementById('addFinanceStatus');
        const input5: any = document.getElementById('addFinanceDescription');
        
        return [
          input1.value,
          input2.value,
          input3.value,
          input4.value,
          input5.value,
        ]
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      if (result && result.value) {
        if (!result.value[0]) {
          Swal.fire({
            icon: 'error',
            title: `Oops...`,
            text: 'Amount is missing!',
            footer: 'Ex: 100',
            showCloseButton: true,
          })
        } else if(!result.value[1]) {
          Swal.fire({
            icon: 'error',
            title: `Oops...`,
            text: 'Type is missing!',
            footer: 'Ex: "profit". Select from the dropdown.',
            showCloseButton: true,
          })
        } else if(!result.value[2]) {
          Swal.fire({
            icon: 'error',
            title: `Oops...`,
            text: 'Payment Method is missing!',
            footer: 'Ex: "cash". Select from the dropdown.',
            showCloseButton: true,
          })
        } else if(!result.value[3]) {
          Swal.fire({
            icon: 'error',
            title: `Oops...`,
            text: 'Status is missing!',
            footer: 'Ex: "processed". Select from the dropdown.',
            showCloseButton: true,
          })
        } else if(!result.value[4]) {
          Swal.fire({
            icon: 'error',
            title: `Oops...`,
            text: 'Description is missing!',
            footer: 'Type something.',
            showCloseButton: true,
          })
        } else {
          // console.log(result);
          const finance = {
            id: null,
            service_id: this.task.service_id,
            owner_id: this.task.staff_id,
            user_id: this.task.user_id,
            description: result.value[4],
            amount: result.value[0],
            created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            client_id: this.task.client_id,
            type: result.value[1],
            status: result.value[3],
            active: 1,
            payment_method: Number(result.value[2])
          }
          
          this.connService
              .addDataValues(finance,'erp_finance')
              .subscribe((res: any) => {
                if (res.data && res.data.affectedRows > 0) {
                  this.global.sweetToast('success', 'Finance was successfully added');
                  if (res.data.insertId) {
                    this.connService.log({
                      uid: (this.user.id ? this.user.id : this.user.uid),
                      createdAt: this.settingsService.currentDate(),
                      content: `User ${this.user.username} has successfully added finance ID #${res.data.insertId}`
                    }).subscribe(log => {
                      // console.log('Login successfully');
                    });
                  } else {
                      this.connService.log({
                        uid: (this.user.id ? this.user.id : this.user.uid),
                        createdAt: this.settingsService.currentDate(),
                        content: `User ${this.user.username} has successfully added a finance activity`
                      }).subscribe(log => {
                        // console.log('Login successfully');
                      });
                  }
                } else {
                  this.global.sweetToast('error', 'Finance activity was not added');
                }
              });
      }
        
        
      }
      
      
    })
  }

}
