import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Staff } from '../classes/staff';
import { ConnectionService } from './connection.service';
import { Team } from '../classes/team';
import { Company } from '../classes/company';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

    private info = new Subject<any>();
    private uidCookieService = new BehaviorSubject<string>('');
    private userSelected = new BehaviorSubject<object>({});
    private loadingStatus = new BehaviorSubject<boolean>(false);
    private menuStatus = new BehaviorSubject<boolean>(false);

    public uid = this.uidCookieService.asObservable();
    public userInfo = this.userSelected.asObservable();
    public loading = this.loadingStatus.asObservable();
    public menu = this.menuStatus.asObservable();

  constructor(
    private conn: ConnectionService,
    private cookieService: CookieService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
      FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  public checkLogin(duration?: number): void {
    if (duration) {
      this.spinner(true, duration);
    } else {
      this.spinner(true, 0);
    }
    let user: any = {};
    this.userInfo.subscribe((res: Staff) => {
      if (res.id && res.username && res.username !== '') {
        this.conn.findById(res.id, 'erp_staff').subscribe((udata: any) => {
          if (udata && udata.data.length > 0) {
            const userData: Staff = udata.data[0];
            user = {
              uid: userData.id,
              convertedLevel: this.convertLevel(userData.access_level),
              level: userData.access_level,
              avatar: userData.avatar,
              email: userData.email,
              name: userData.name,
              phone: {
                primary: userData.phone_primary,
                secondary: userData.phone_secondary
              },
              username: userData.username,
              address_full: this.fullAddress(userData)
            };

            this.conn.findById(userData.team_id, 'erp_team').subscribe((team: any) => {
              if (team.data.length > 0) {
                const selectedTeam: Team = team.data[0];
                user.team = {
                  id: selectedTeam.id,
                  name: selectedTeam.name
                };
                if (team.data[0].leader_id) {
                  this.conn.findById(team.data[0].leader_id, 'erp_staff').subscribe((leader: any) => {
                    const selectedLeader: Staff = leader.data[0];
                    user.leader = {
                      name: selectedLeader.name,
                      email: selectedLeader.email,
                      phone: {
                        primary: selectedLeader.phone_primary,
                        secondary: selectedLeader.phone_secondary
                      },
                      avatar: selectedLeader.avatar
                    };
                  });
                } else {
                  user.leader = {};
                }
              } else {
                user.team = {};
              }
            });

            this.conn.findById(userData.company_id, 'erp_company').subscribe((company: any) => {
              if (company.data.length > 0) {
                const selectedCompany: Company = company.data[0];
                user.company = {
                  id: selectedCompany.id,
                  name: selectedCompany.name,
                  address: this.fullAddress(selectedCompany),
                  email: selectedCompany.email,
                  website: selectedCompany.website,
                  logo: selectedCompany.company_logo,
                  slogan: selectedCompany.company_slogan,
                  phone: {
                    primary: selectedCompany.phone_primary,
                    secondary: selectedCompany.phone_secondary
                  }
                };
              } else {
                user.company = {};
              }

            });

            this.updateUserInfo(user);

          } else {
            this.logout(user);
          }

        });
      } else if (res.avatar && res.name && res.username && res.email) {
        user = res;
      } else {
        this.logout(user);
      }
    });
    this.spinner(false, 1000);
  }

  public checkCookie(): void {
    const cookieExists: boolean = this.cookieService.check('uid');
    if (cookieExists) {
      const uidCookie: string = this.cookieService.get('uid');
      this.uid.subscribe(res => {
        if (res !== uidCookie) {
          this.conn.log({
            uid: this.uid.subscribe(res => res),
            createdAt: this.currentDate(),
            content: `User ${this.userInfo.subscribe((res: any) => res.username)} has logout successfully`
          })
          this.cookieService.delete('uid');
          this.router.navigate(['/signin'], {relativeTo: this.activatedRoute});
        }
      });
    } else {
      this.router.navigate(['/signin'], {relativeTo: this.activatedRoute});
    }
  }

  public convertLevel(level: string) {
    let userLevelConverted: number;
    if (level === 'staff') {
      userLevelConverted = 1;
    } else if (level === 'supervisor') {
      userLevelConverted = 2;
    } else if (level === 'manager') {
      userLevelConverted = 3;
    } else if (level === 'owner') {
      userLevelConverted = 4;
    } else if (level === 'developer') {
      userLevelConverted = 5;
    } else {
      userLevelConverted = 0;
    }
    return userLevelConverted;
  }

  public logout(user: any): void {
    let id: any;
    if (user.id) {
      id = user.id;
    } else {
      id = user.uid;
    }
    this.conn.log({
      uid: id,
      createdAt: this.currentDate(),
      content: `User ${user.username} has logout successfully`
    }).subscribe(log => {
      // console.log('logout successfully');
      this.cookieService.delete('uid');
      this.router.navigate(['/signin'], {relativeTo: this.activatedRoute});
      this.spinner(false, 100);
    });
    // window.location.reload();
  }

  public fullAddress(userData) {
    let addressFull = '';
    const separator = ', ';
    if (userData.address_number && userData.address_number !== '') {
      addressFull += userData.address_number + ' ';
      if (userData.address_street && userData.address_street !== '') {
        addressFull += userData.address_street + separator;
        if (userData.address_comp && userData.address_comp !== '') {
          addressFull += userData.address_city + separator;
        }
      }
    }

    if (userData.address_city && userData.address_city !== '') {
      addressFull += userData.address_city + separator;
    }

    if (userData.address_state && userData.address_state !== '') {
      addressFull += userData.address_state + separator;
    }

    if (userData.address_country && userData.address_country !== '') {
      addressFull += userData.address_country;
      if (userData.address_zipcode && userData.address_zipcode !== '') {
        addressFull += ' - ' + userData.address_zipcode;
      }
    }

    return addressFull;
  }

  public updateMenu(status: boolean) {
    this.menuStatus.next(status);
  }

  public updateUserInfo(data: object) {
    this.userSelected.next(data);
  }

  public uidUpdate(uid: string) {
    this.uidCookieService.next(uid);
  }

  public loadingUpdate(status: boolean) {
    this.loadingStatus.next(status);
  }

  public getInfo(): Observable<any> {
    return this.info.asObservable();
  }

  public setInfo(info: object) {
    this.info.next(info);
  }

  public spinner(status?: boolean, time?: number) {
    if (status) {
      this.loadingUpdate(status);
    } else {
      setTimeout(() => {
        this.loadingUpdate(status);
      }, time);
    }
  }

  public capitalize(s: string) {
    let result: string;
    if (typeof s !== 'string') {
      result = '';
    } else {
      result = s.charAt(0).toUpperCase() + s.slice(1);
    }
    return result;
  }

  public getPathname() {
    const url = window.location.pathname;
    return url.substring(url.lastIndexOf('/') + 1);
  }

  public encryptSHA256(value) {
    const parseValue = CryptoJS.enc.Utf8.parse(value);
    const encrypted = CryptoJS.SHA256(parseValue);
    return encrypted.toString();
  }

  public encryptAES(keys, value) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const ive = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: ive,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  // The get method is use for decrypt the value.
  public decryptAES(keys, value) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const ive = CryptoJS.enc.Utf8.parse(keys);
    const decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: ive,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public currentDate(): string {
    const date = new Date();
    const separatorDate = '-';
    const separatorTime = ':';
    let month: any = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    return  date.getFullYear() +
            separatorDate +
            month +
            separatorDate +
            date.getDate() + ' ' +
            date.getHours() +
            separatorTime +
            date.getMinutes() +
            separatorTime +
            date.getSeconds();
  }

  public searchTable(inputId: string, tableId: string) {
    // Declare variables
    // tslint:disable-next-line: one-variable-per-declaration
    let input, filter, table, tr, td, i, txtValue;
    input = document.getElementById(inputId);
    filter = input.value.toUpperCase();
    table = document.getElementById(tableId);
    tr = table.getElementsByTagName('tr');

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName('td')[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = '';
        } else {
          tr[i].style.display = 'none';
        }
      }
    }
  }

  public sortTable(tableId: string) {
  // tslint:disable-next-line: one-variable-per-declaration
    let table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById(tableId);
    switching = true;
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      // start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        // start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName('TD')[0];
        y = rows[i + 1].getElementsByTagName('TD')[0];
        // check if the two rows should switch place:
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          // if so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

}
