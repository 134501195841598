import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MomentModule } from 'angular2-moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SigninComponent } from './pages/signin/signin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecoverComponent } from './pages/recover/recover.component';
import { CookieService } from 'ngx-cookie-service';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NotesComponent } from './pages/notes/notes.component';
import { AutosizeModule } from 'ngx-autosize';
import { NgxEditorModule } from 'ngx-editor';
import { Globals } from './globals.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalNotesComponent } from './modals/modal-notes/modal-notes.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalCalendarComponent } from './modals/modal-calendar/modal-calendar.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { ChatComponent } from './pages/chat/chat.component';
import { ClientComponent } from './pages/client/client.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { MzButtonModule, MzModalModule, MzDatepickerModule } from 'ngx-materialize';
import { ModalUserComponent } from './modals/modal-user/modal-user.component';
import { ModalClientComponent } from './modals/modal-client/modal-client.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { FinanceComponent } from './pages/finance/finance.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxCurrencyModule } from 'ngx-currency';
import { OrderByColumnPipe } from './pipes/order-by-column.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { RecordsComponent } from './pages/records/records.component';
import { WebsiteComponent } from './pages/website/website.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { TaskComponent } from './components/task/task.component';
import { SortableModule, TimepickerModule } from 'ngx-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LogComponent } from './components/log/log.component';
import { FileComponent } from './pages/file/file.component';
import { ClientsComponent } from './pages/file/clients/clients.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SigninComponent,
    DashboardComponent,
    RecoverComponent,
    TopbarComponent,
    SpinnerComponent,
    NotesComponent,
    ModalNotesComponent,
    CalendarComponent,
    ModalCalendarComponent,
    ChatComponent,
    ClientComponent,
    PhonePipe,
    SortPipe,
    ModalUserComponent,
    ModalClientComponent,
    OnlyNumberDirective,
    FinanceComponent,
    OrderByColumnPipe,
    OrderByDatePipe,
    RecordsComponent,
    WebsiteComponent,
    ProfileComponent,
    AdminComponent,
    TaskComponent,
    LogComponent,
    FileComponent,
    ClientsComponent,
    DateAgoPipe,
    SortByPipe
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    MomentModule,
    AutosizeModule,
    NgxEditorModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    NgxPaginationModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    MzButtonModule,
    MzModalModule,
    MzDatepickerModule,
    TypeaheadModule.forRoot(),
    NgxCurrencyModule,
    SortableModule.forRoot(),
    DragDropModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
  ],
  providers: [
    CookieService,
    Globals
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
