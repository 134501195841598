import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as S3 from 'aws-sdk/clients/s3';
import { Globals } from 'src/app/globals.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  private apiURL = environment.apiURL;
  // private apiURL = 'http://ec2-3-89-193-142.compute-1.amazonaws.com:3002/api';
  private key = environment.key;
  private autoSuggestPlaceUrl = environment.autoSuggestPlace; 

  constructor(
    private http: HttpClient,
    private global: Globals
  ) {}

  /**
   * getInfo
   */
  public getInfo() {
    return this.http.post(this.apiURL + '/info', {
        oauth: this.key
    });
  }

  public findById(uid: any, col: any) {
    return this.http.post(this.apiURL + '/find', {
        oauth: this.key,
        collection: col,
        column: 'id',
        operator: '=',
        text: uid
    });
  }

  public findEmail(email: string) {
    return this.http.post(this.apiURL + '/find', {
        oauth: this.key,
        collection: 'erp_staff',
        column: 'email',
        operator: '=',
        text: email
    });
  }

  public checkEmail(userEmail: string) {
    return this.http.post(this.apiURL + '/checkEmail', {
        oauth: this.key,
        email: userEmail
    });
  }

  public sendRecoverEmail(userEmail: string) {
    // return this.http.post(this.apiURL + '/sendmail', {}, {
    //   params: {
    //     email: userEmail,
    //     oauth: this.key
    //   },
    //   responseType: 'text'
    // });
  }

  public updateSignInStaff(email, pass) {
    return this.http.post(this.apiURL + '/update', { 
          collection: 'erp_staff',
          oauth: this.key,
          column: 'email',
          operator: '=',
          value: email,
          body: {password: pass}
      }
    );
  }

  public signin(user, pass) {
    return this.http.post(this.apiURL + '/login', {
        oauth: this.key,
        collection: 'erp_staff',
        username: user,
        password: pass
    });
  }

  public log(bodyData: object) {
    return this.http.post(this.apiURL + '/add', {
        oauth: this.key,
        collection: 'erp_log',
        body: bodyData
    });
  }

  public getData(uid: string, table: string) {
    return this.http.post(this.apiURL + '/find', {
        oauth: this.key,
        collection: table,
        column: 'uid',
        operator: '=',
        text: uid
    });
  }

  public searchData(input: string, table: string, col: string) {
    return this.http.post(this.apiURL + '/search', {
        oauth: this.key,
        collection: table,
        column: col,
        text: input
    });
  }

  public searchPlace(input: string, entityType?: string) {    
    let entity;
    if (entityType) {
      entity = entityType;
    } else {
      entity = 'Address';
    }
    const parameters = {
      query: input,
      includeEntityTypes: entity,
      key: environment.searchPlaceKey
    };
    return  this.http
                .get(this.autoSuggestPlaceUrl, {params:parameters})
                .toPromise();
  }

  public searchClient(input: string, col: string) {
    return this.http.post(this.apiURL + '/searchclient', {
        oauth: this.key,
        column: col,
        text: input
    });
  }

  public searchRecord(input: string) {

    return this.http.post(this.apiURL + '/searchrecord', {
        oauth: this.key,
        column: 'description',
        text: input
    });
  }

  public searchTransaction(input: string, col: string, from?: string, to?: string) {
    return this.http.post(this.apiURL + '/searchtransaction', {
        oauth: this.key,
        column: col,
        text: input,
        from: `${from}`,
        to: `${to}`
    });
  }

  public addData(bodyData: object, table: string) {
    return this.http.post(this.apiURL + '/add', {
        oauth: this.key,
        collection: table,
        body: bodyData
      });
  }
  
  public addDataValues(bodyData: object, table: string) {
    return this.http.post(this.apiURL + '/add-values', {
        oauth: this.key,
        collection: table,
        body: bodyData
      });
  }

  public updateData(data: object, id: any, table: string) {
    return this.http.post(this.apiURL + '/update', {
          collection: table,
          oauth: this.key,
          column: 'id',
          operator: '=',
          value: id,
          body: data
      }
    );
  }

  public deleteData(id, table: string) {
    return this.http.post(this.apiURL + '/delete', {
        oauth: this.key,
        collection: table,
        column: 'id',
        operator: '=',
        value: id
    });
  }
  public getCustomers() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_customer'
    });
  }
  public getLogs() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_log'
    });
  }
  public getPaymentMethod() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_payment'
    });
  }
  public getDocs() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_doc'
    });
  }

  public getRecord() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_record'
    });
  }

  public getServices() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_service'
    });
  }

  public getStaff() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_staff'
    });
  }

  public getTasks() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_task'
    });
  }

  public getTransaction() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_finance'
    });
  }

  public getFiles() {
    return this.http.post(this.apiURL + '/getall', {
        oauth: this.key,
        collection: 'erp_file'
    });
  }
  
  public getWebsiteInfo() {
    return this.http.post(this.apiURL + '/website-info', {
        oauth: this.key
    });
  }

  public getBusinessHours() {
    return this.http.post(this.apiURL + '/website-business-hours', {
        oauth: this.key
    });
  }

  public updatePopupWebsite(data: object) {
    return this.http.post(this.apiURL + '/website-update', {
          oauth: this.key,
          body: data
      }
    );
  }

  public updateBusinessHours(data: object, day: string) {
    return this.http.post(this.apiURL + '/update-business-hours', {
            oauth: this.key,
            value: day,
            body: data
          }
    );
  }

  public S3Conn() {
    return new S3(
      {
        accessKeyId: this.global.S3_BUCKET_ACCESS_KEY_ID,
        secretAccessKey: this.global.S3_BUCKET_SECRET_ACCESS_KEY,
        region: environment.s3BucketRegion
      }
    );
  } 
}
