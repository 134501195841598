import { Component, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  public loading: boolean = false;
  public spinnerConfig = {
    animationType:                ngxLoadingAnimationTypes.threeBounce,
    backdropBackgroundColour:     'rgba(255,255,255,0.3)',
    primaryColour:                '#ffffff',
    secondaryColour:              'teal',
    tertiaryColour:               '#ffffff',
    backdropBorderRadius:         0,
    fullScreenBackdrop:           true,
  };

  constructor(private settings: SettingsService) {
  }
  
  ngOnInit() {
    this.settings.loading.subscribe(res => {
      this.loading = res;
    });
  }

}
