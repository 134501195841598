import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { } from 'googlemaps';
import * as moment from 'moment';
import { from, Observable, Observer } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { Client } from 'src/app/classes/client';
import { Globals } from 'src/app/globals.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SettingsService } from 'src/app/services/settings.service';



@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  @ViewChild('map', {static: false}) mapElement: any;
  map: google.maps.Map;

  user;
  listClients: Array<object>;
  pager: any = {};
  pagedItems: any[];
  records: any[];
  clientTransactions: any[];
  clientTasks: any[];
  searchInput: any;
  searchInputRecord: any;
  searchInputFinance: any;
  clientSelected: any = {};
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '80px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
            'redo',
            'link',
            'unlink',
            'video'
        ],
     ]
  };
  editableMode = false;
  clientSelectedAddress = '';
  clientSelectedAddressObj = {
    address_number: '',
    address_street: '',
    address_city: '',
    address_county: '',
    address_state: '',
    address_country: '',
    address_zipcode: ''
  };
  public modal = false;
  listDocs = [];
  @ViewChild('modal', {static: false}) modalElem;
  path;
  addressSelectedQuery = '';
  services: any = [];
  staffArr: any = [];
  fileList$: Observable<any[]>;
  customAddressToggle = false;
  searchClientBy = 'name';
  places$: Observable<Array<object>>;

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals,
              private pagination: PaginationService,
              private domSanitizer: DomSanitizer) {

              }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.path = this.global.path;
    this.getStaffs();
    this.getServices();
    this.docs();
    this.getFinance();
    this.getRecords();
    setTimeout(() => {
      this.getClients();
      if (this.user) {
        this.settingsService.spinner(false, 100);
      }
    }, 1000);
  }

  toggleAddress(event) {
    if (event.target.checked) {
      this.customAddressToggle = true;
    } else {
      this.customAddressToggle = false;
    }
  }

  handleCapitalizedString( name ): string {
    return name.toUpperCase();
  }

  updateAddress() {
    this.searchPlace(this.clientSelectedAddress);
    // console.log(this.clientSelectedAddressObj);
    if (Number(this.clientSelectedAddressObj.address_number)) {
      if (Number(this.clientSelectedAddressObj.address_zipcode)) {
        this.clientSelected.address_number = this.clientSelectedAddressObj.address_number;
        this.clientSelected.address_street = this.clientSelectedAddressObj.address_street;
        this.clientSelected.address_city = this.clientSelectedAddressObj.address_city;
        this.clientSelected.address_county = this.clientSelectedAddressObj.address_county;
        this.clientSelected.address_state = this.clientSelectedAddressObj.address_state;
        this.clientSelected.address_country = this.clientSelectedAddressObj.address_country;
        this.clientSelected.address_zipcode = this.clientSelectedAddressObj.address_zipcode;
        this.global.sweetToast('success', `Address updated successfully. You must save it.`);
      } else {
        this.global.sweetToast('error', `Zipcode must be a number (Ex: 30157)`);
      }
    } else {
      this.global.sweetToast('error', `Address must start with a street number (Ex: 32 Timber...)`);
    }
  }

  // searchPlace(input) {
  //   const autocomplete = new google.maps.places.Autocomplete(input);
  //   autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
  //   autocomplete.addListener('place_changed', () => {
  //     const place = autocomplete.getPlace();
  //     if (!place.geometry) {
  //       // User entered the name of a Place that was not suggested and
  //       // pressed the Enter key, or the Place Details request failed.
  //       window.alert(`No details available for input: ${place.name}`);
  //       return;
  //     }
  //     let address = '';
  //     if (place.address_components) {
  //       if (place.address_components[0] && (place.address_components[0].short_name || place.address_components[0].long_name)) {
  //         if (place.address_components[0].long_name) {
  //           address = place.address_components[0].long_name;
  //           this.clientSelectedAddressObj.address_number = place.address_components[0].long_name;
  //         } else {
  //           address = place.address_components[0].short_name;
  //           this.clientSelectedAddressObj.address_number = place.address_components[0].short_name;
  //         }
  //       }
  //       if (place.address_components[1] && (place.address_components[1].short_name || place.address_components[1].long_name)) {
  //         if (place.address_components[1].long_name) {
  //           address += ` ${place.address_components[1].long_name}`;
  //           this.clientSelectedAddressObj.address_street = place.address_components[1].long_name;
  //         } else {
  //           address += ` ${place.address_components[1].short_name}`;
  //           this.clientSelectedAddressObj.address_street = place.address_components[1].short_name;
  //         }
  //       }
  //       if (place.address_components[2] && (place.address_components[2].short_name || place.address_components[2].long_name)) {
  //         if (place.address_components[2].long_name) {
  //           address += `, ${place.address_components[2].long_name}`;
  //           this.clientSelectedAddressObj.address_city = place.address_components[2].long_name;
  //         } else {
  //           address += `, ${place.address_components[2].short_name}`;
  //           this.clientSelectedAddressObj.address_city = place.address_components[2].short_name;
  //         }
  //       }
  //       if (place.address_components[3] && (place.address_components[3].short_name || place.address_components[3].long_name)) {
  //         if (place.address_components[3].long_name) {
  //           address += `, ${place.address_components[3].long_name}`;
  //           this.clientSelectedAddressObj.address_county = place.address_components[3].long_name;
  //         } else {
  //           address += `, ${place.address_components[3].short_name}`;
  //           this.clientSelectedAddressObj.address_county = place.address_components[3].short_name;
  //         }
  //       }
  //       if (place.address_components[4] && (place.address_components[4].short_name || place.address_components[4].long_name)) {
  //         if (place.address_components[4].long_name) {
  //           address += `, ${place.address_components[4].short_name}`;
  //           this.clientSelectedAddressObj.address_state = place.address_components[4].short_name;
  //         } else {
  //           address += `, ${place.address_components[4].long_name}`;
  //           this.clientSelectedAddressObj.address_state = place.address_components[4].long_name;
  //         }
  //       }
  //       if (place.address_components[5] && (place.address_components[5].short_name || place.address_components[5].long_name)) {
  //         if (place.address_components[5].long_name) {
  //           address += `, ${place.address_components[5].short_name}`;
  //           this.clientSelectedAddressObj.address_country = place.address_components[5].short_name;
  //         } else {
  //           address += `, ${place.address_components[5].long_name}`;
  //           this.clientSelectedAddressObj.address_country = place.address_components[5].long_name;
  //         }
  //       }
  //       if (place.address_components[6] && (place.address_components[6].short_name || place.address_components[6].long_name)) {
  //         if (place.address_components[6].long_name) {
  //           address += ` ${place.address_components[6].short_name}`;
  //           this.clientSelectedAddressObj.address_zipcode = place.address_components[6].short_name;
  //         } else {
  //           address += ` ${place.address_components[6].long_name}`;
  //           this.clientSelectedAddressObj.address_zipcode = place.address_components[6].long_name;
  //         }
  //       }
  //       if (place.address_components[7] && (place.address_components[7].short_name || place.address_components[7].long_name)) {
  //         if (place.address_components[7].long_name) {
  //           address += `-${place.address_components[7].short_name}`;
  //         } else {
  //           address += `-${place.address_components[7].long_name}`;
  //         }
  //       }
  //     }
  //     this.clientSelectedAddress = address;
  //   });
  // }

  searchPlace(input: string) {
    const value: string = input;
    if (value.length > 2) {
      this.searchPlaceBing(input);
    }
  }

  selectPlace(item) {
    const iterator = item.address;
    this.clientSelectedAddressObj.address_number = iterator.address_number;
    this.clientSelectedAddressObj.address_street = iterator.address_street;
    this.clientSelectedAddressObj.address_city = iterator.address_city;
    this.clientSelectedAddressObj.address_county = iterator.address_county;
    this.clientSelectedAddressObj.address_state = iterator.address_state;
    this.clientSelectedAddressObj.address_country = iterator.address_country;
    this.clientSelectedAddressObj.address_zipcode = iterator.address_zipcode;
    this.clientSelectedAddress = item.place;
    this.places$ = new Observable<Array<object>>( ( observer: Observer<Array<object>> ) => {
      observer.next( [] );
    } );
  }

  searchPlaceBing(input) {
    this.conn
      .searchPlace( input.value )
      .then( ( res: any ) => {
        const arr = res.resourceSets[ 0 ].resources[ 0 ].value;
        const newArr = [];
        for ( const item of arr ) {
          const iterator = item.address;
          newArr.push( {
            place: iterator.formattedAddress,
            address: {
              address_number: iterator.houseNumber,
              address_street: iterator.streetName,
              address_city: iterator.locality,
              address_county: iterator.adminDistrict2,
              address_state: iterator.adminDistrict,
              address_country: iterator.countryRegionIso2,
              address_zipcode: iterator.postalCode
            }
          } );
        }
        this.places$ = new Observable<Array<object>>( ( observer: Observer<Array<object>> ) => {
          observer.next( newArr );
        } );
      } )
      .catch( ( err: HttpErrorResponse ) => {
        console.log( err );
        this.searchPlaceGoogle( input );
        console.log( 'Getting results from Google' );
      } );
  }

  searchPlaceGoogle(input) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert(`No details available for input: ${place.name}`);
        return;
      }
      let address = '';
      if (place.address_components) {
        if (place.address_components[0] && (place.address_components[0].short_name || place.address_components[0].long_name)) {
          if (place.address_components[0].long_name) {
            address = place.address_components[0].long_name;
            this.clientSelectedAddressObj.address_number = place.address_components[0].long_name;
          } else {
            address = place.address_components[0].short_name;
            this.clientSelectedAddressObj.address_number = place.address_components[0].short_name;
          }
        }
        if (place.address_components[1] && (place.address_components[1].short_name || place.address_components[1].long_name)) {
          if (place.address_components[1].long_name) {
            address += ` ${place.address_components[1].long_name}`;
            this.clientSelectedAddressObj.address_street = place.address_components[1].long_name;
          } else {
            address += ` ${place.address_components[1].short_name}`;
            this.clientSelectedAddressObj.address_street = place.address_components[1].short_name;
          }
        }
        if (place.address_components[2] && (place.address_components[2].short_name || place.address_components[2].long_name)) {
          if (place.address_components[2].long_name) {
            address += `, ${place.address_components[2].long_name}`;
            this.clientSelectedAddressObj.address_city = place.address_components[2].long_name;
          } else {
            address += `, ${place.address_components[2].short_name}`;
            this.clientSelectedAddressObj.address_city = place.address_components[2].short_name;
          }
        }
        if (place.address_components[3] && (place.address_components[3].short_name || place.address_components[3].long_name)) {
          if (place.address_components[3].long_name) {
            address += `, ${place.address_components[3].long_name}`;
            this.clientSelectedAddressObj.address_county = place.address_components[3].long_name;
          } else {
            address += `, ${place.address_components[3].short_name}`;
            this.clientSelectedAddressObj.address_county = place.address_components[3].short_name;
          }
        }
        if (place.address_components[4] && (place.address_components[4].short_name || place.address_components[4].long_name)) {
          if (place.address_components[4].long_name) {
            address += `, ${place.address_components[4].short_name}`;
            this.clientSelectedAddressObj.address_state = place.address_components[4].short_name;
          } else {
            address += `, ${place.address_components[4].long_name}`;
            this.clientSelectedAddressObj.address_state = place.address_components[4].long_name;
          }
        }
        if (place.address_components[5] && (place.address_components[5].short_name || place.address_components[5].long_name)) {
          if (place.address_components[5].long_name) {
            address += `, ${place.address_components[5].short_name}`;
            this.clientSelectedAddressObj.address_country = place.address_components[5].short_name;
          } else {
            address += `, ${place.address_components[5].long_name}`;
            this.clientSelectedAddressObj.address_country = place.address_components[5].long_name;
          }
        }
        if (place.address_components[6] && (place.address_components[6].short_name || place.address_components[6].long_name)) {
          if (place.address_components[6].long_name) {
            address += ` ${place.address_components[6].short_name}`;
            this.clientSelectedAddressObj.address_zipcode = place.address_components[6].short_name;
          } else {
            address += ` ${place.address_components[6].long_name}`;
            this.clientSelectedAddressObj.address_zipcode = place.address_components[6].long_name;
          }
        }
      }
      this.clientSelectedAddress = address;
    });
  }


  deleteClient() {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        this.conn.deleteData(this.clientSelected.id, 'erp_customer').subscribe((res: any) => {
          if (res.data && res.data.affectedRows > 0) {
            this.getClients();
            this.global.sweetToast('success', 'Client was successfully deleted');
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `Client ${this.clientSelected.name} has successfully deleted. Client ID #${this.clientSelected.id}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
            this.editableMode = false;
            this.clientSelected = {};
          } else {
            this.global.sweetToast('error', 'Sorry! Note was not deleted. Please try again');
          }
        });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });
  }

  updateClient(id) {
    const client  = this.clientSelected;
    delete client.id;
    delete client.dtformatted;
    delete client.created_at;
    delete client.updated_at;
    delete client.doc_id;
    delete client.doc_desc;
    delete client.recordsObj;
    this.conn.updateData(client, id, 'erp_customer').subscribe(async (res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.global.sweetToast('success', `Client ${this.clientSelected.name} was successfully updated`);
          this.clientSelected = {};
          this.getClients();
          setTimeout(() => {
            this.search();
          }, 500);
          this.search();
          this.conn.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `Client ${this.clientSelected.name} was successfully updated. Client ID #${id}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
          this.editableMode = false;
        } else {
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.global.sweetToast('error', 'Sorry! Client was not updated. Please try again');
      }
    });
  }

  changeEditMode() {
    if (this.editableMode) {
      this.editableMode = false;
    } else {
      this.editableMode = true;
    }
  }

  cleanSearchInput() {
    this.searchInput = '';
  }

  search() {

    const type = this.searchClientBy;

    if (this.searchInput && type) {
      let field;

      if (type === 'customer_company') {
        field = 'customer_company';
      } else if (type === 'phone') {
        field = 'phone_primary';
      } else {
        field = 'name';
      }

      this.conn
          .searchClient(this.searchInput, field)
          .subscribe((clients: any) => {
            from(clients.data)
            .pipe(
                map((client: Client) => ({
                  ...client,
                  dob: moment.utc(client.dob).format('YYYY-MM-DD'),
                  dtformatted: moment.utc(client.dob).format('MMM DD, YYYY'),
                  recordsObj: this.records.filter((record: any) => Number(record.client_id) === Number(client.id))
                })),
                filter((client: Client) => client.access_status === 'active'), toArray()
              )
              .subscribe((customers) => {
                this.listClients = customers;
                this.setPage(1);
              });
          });
    } else {
      this.getClients();
    }

  }

  searchRecord() {
    if (this.searchInputRecord) {
      this.conn
          .searchRecord(this.searchInputRecord)
          .subscribe((rec: any) => {
            from(rec.data)
            .pipe(filter((record: any) => Number(record.active) === 1 && Number(record.client_id) === Number(this.clientSelected.id)), toArray())
            .subscribe((rc) => {
              if (rc.length) {
                this.clientSelected.recordsObj = rc;
              } else {
                this.global.sweetToast('warning', `No results found`);
              }
            });
          });
    } else {
      this.conn
          .getRecord()
          .subscribe((rcd: any) => {
            from(rcd.data)
              .pipe(filter((record: any) => Number(record.active) === 1 && Number(record.client_id) === Number(this.clientSelected.id)), toArray())
              .subscribe(records => this.clientSelected.recordsObj = records);
          });
    }
  }

  getServices() {
    this.conn
        .getServices()
        .subscribe((res: any) => {
          this.services = res.data;
        });
  }

  byPassUrlMaps() {
    return this.domSanitizer
      .bypassSecurityTrustResourceUrl(
        `https://maps.google.com/maps?q=${ this.clientSelectedAddress }&t=&z=13&ie=UTF8&iwloc=&output=embed`
      );
  }

  getClients() {
    this.conn
        .getCustomers()
        .subscribe((clients: any) => {
          from(clients.data)
            .pipe(
                filter((client: Client) => client.access_status === 'active'),
                map((client: Client) => ({
                  ...client,
                  dob: moment.utc(client.dob).format('YYYY-MM-DD'),
                  dtformatted: moment.utc(client.dob).format('LLL'),
                })),
                toArray()
              )
            .subscribe((customers) => {
              this.listClients = customers.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.setPage(1);
            });
        });
  }

  returnServiceTitle(arr) {
    let result;
    from( this.services )
      .pipe( filter( ( srv: any ) => Number(srv.id) === Number(arr.service_id) ),
      map( ( srv: any ) => srv.title ) ).subscribe( ( res: string ) => {
      result = res;
    } );
    return (result) ? result : '';
  }

  getRecords() {
    this.conn
        .getRecord()
        .subscribe((rcd: any) => {
          from(rcd.data)
            .pipe(
              filter((record: any) => Number(record.client_id) === Number(this.clientSelected.id)),
              map((rc: any) => ({
                ...rc,
                service_title: this.returnServiceTitle(rc)
              })),
              toArray()
            )
            .subscribe((records) => {
              this.records = records;
            });
        });
  }

  getStaffs() {
    this.conn
        .getStaff()
        .subscribe((s: any) => {
          this.staffArr = s.data;
        });
  }

  returnStaffName(arr) {
    let result;
    from( this.staffArr )
      .pipe( filter( ( srv: any ) => Number(srv.id) === ( Number(arr.owner_id) || Number(arr.staff_id) ) ),
        map( ( srv: any ) => srv.name ) ).subscribe( ( res: string ) => {
          result = res;
        } );
    return (result) ? result : '';
  }

  returnClientName(arr) {
    let result;
    from( this.listClients )
      .pipe( filter( ( srv: any ) => Number(srv.id) === Number(arr.client_id) ),
      map( ( srv: any ) => srv.name ) ).subscribe( ( res: string ) => {
      result = res;
    } );
    return (result) ? result : '';
  }

  getTask() {
    this.conn
        .getTasks()
        .subscribe((res: any) => {
          from(res.data)
          .pipe(
            filter((task: any) => Number(task.client_id) === Number(this.clientSelected.id)),
            map((task: any) => ({
                ...task,
                client_name: this.returnClientName(task),
                service_title: this.returnServiceTitle(task),
                user_name: this.returnStaffName(task)
            })),
            toArray()
          ).subscribe((srvArr: any) => {
            this.clientTasks = srvArr;
          });
    });
  }

  getFinance() {
    this.conn
        .getTransaction()
        .subscribe((res: any) => {
          from(res.data)
              .pipe(
                filter((transaction: any) => Number(transaction.client_id) === Number(this.clientSelected.id)),
                map((transaction: any) => ({
                    ...transaction,
                    amount: Number(transaction.amount),
                    service_title: this.returnServiceTitle(transaction),
                    user_name: this.returnStaffName(transaction)
                })),
                toArray()
              ).subscribe((srvArr: any) => {
                this.clientTransactions = srvArr;
              });
        });
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagination.getPager(this.listClients.length, page, 11);

    // get current page of items
    this.pagedItems = this.listClients.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  toggleModal() {
    if (this.modal) {
      this.modal = false;
      this.modalElem.closeModal();
    } else {
      this.modal = true;
      this.modalElem.openModal();
    }
  }

  dismissModal() {
    this.modalElem.closeModal();
  }

  numbersOnly(event): boolean {
   return this.global.numberOnly(event);
  }

  docs() {
    this.conn.getDocs()
              .subscribe((res: any) => {
                this.listDocs = res.data;
              });
  }

  selectClient(selectedId: any) {
    this.clientSelected = Object.assign({}, this.listClients.find((client: any) => Number(client.id) === Number(selectedId)));

    if (this.clientSelected.address_number) {
      this.clientSelectedAddress = `${this.clientSelected.address_number}`;
    }
    if (this.clientSelected.address_street) {
      this.clientSelectedAddress += ` ${this.clientSelected.address_street}`;
    }
    if (this.clientSelected.address_city) {
      this.clientSelectedAddress += `, ${this.clientSelected.address_city}`;
    }
    if (this.clientSelected.address_state) {
      this.clientSelectedAddress += `, ${this.clientSelected.address_state}`;
    }
    // if (this.clientSelected.address_country) {
    //   this.clientSelectedAddress += `, ${this.clientSelected.address_country}`;
    // }
    if (this.clientSelected.address_zipcode) {
      this.clientSelectedAddress += ` ${this.clientSelected.address_zipcode}`;
    }

    this.getServices();
    this.getRecords();
    this.getFinance();
    this.getFilesList();
    this.getTask();
  }

  generateUrl(bucket, key) {
    const params = {Bucket: bucket, Key: key};
    const conn = this.conn.S3Conn();
    const url = conn.getSignedUrl('getObject', params);

    if (url) {
      window.open( url, 'new' );
    } else {
      this.global.sweetToast('error', 'Sorry! We could not find this file');
    }
  }

  getFilesList() {
    this.conn.getFiles()
      .subscribe( ( res: any ) => {
        this.fileList$ = from( res.data )
          .pipe(
            filter( ( file: any ) => Number(file.client_id) === Number(this.clientSelected.id) ),
            toArray()
          );
      } );
  }

  copyAddress () {
    const nav: any = window.navigator;
    nav.clipboard.writeText( this.clientSelectedAddress ).then( () => {
      this.global.toastFunc( 'Copying address to clipboard was successful!', 4000 );
    }, ( err ) => {
      console.error( 'Async: Could not copy address: ', err );
    } );
  }

}
