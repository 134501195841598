import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

    getPager(totalItems: number, currentPage: number = 1, pageSize?: number, pageQty?: number) {
        // calculate total pages
        if (!pageSize) {
            pageSize = 10;
        }
        if (!pageQty) {
            pageQty = 2;
        }
        const totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number;
        let endPage: number;
        if (totalPages <= pageQty) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            let divisor;
            if (Number.isFinite((totalPages / 2))) {
                divisor = (pageQty / 2);
            } else {
                divisor = (pageQty / 2) + 1;
            }
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= pageQty) {
                startPage = 1;
                endPage = pageQty;
            } else if ((currentPage + 1) >= pageQty && currentPage < (totalPages - (pageQty / 2))) {
                startPage = currentPage - divisor;
                endPage = currentPage + divisor;
            } else if (currentPage >= (totalPages - divisor)) {
                startPage = currentPage - divisor;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

}
