import { Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public user: any;
  public modalType: string;
  public modalData: any;
  public modalElemData: any;
  @ViewChild('modal', {static: false}) modalElem;

  @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent; // the #calendar in the template
  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = [
    {}
  ];
  myDateValue: Date;
  modalStatus = false;

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService) {}

  ngOnInit() {
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.renderEvents(this.user.uid);
  }

  open() {
    this.modalType = 'add';
    this.modalStatus = true;
    this.modalElem.openModal();
  }

  renderEvents(uid) {
    this.conn.getData(uid, 'erp_calendar').subscribe((res: any) => {
      const events = [];
      for (const element of res.data) {
        events.push({
          id: element.id,
          groupId: element.groupId,
          title: element.title,
          allDay: this.parseBoolean(element.allDay),
          start: new Date (element.startAt),
          end: new Date (element.endAt),
          editable: true,
          resourceEditable: true,
          active: element.active,
          uid: element.uid
        });
      }
      this.calendarEvents = events;
      const calendarApi = this.calendarComponent.getApi();
      if (calendarApi && calendarApi !== null) {
        calendarApi.render();
      }
      this.myDateValue = new Date();
    });

  }

  updateModalStatus() {
    if (this.modalStatus) {
      this.modalStatus = false;
      this.modalElem.closeModal();
    } else {
      this.modalStatus = true;
      this.modalElem.openModal();
    }
  }

  clickEvent(info: any) {
    this.modalElemData = info;
    this.modalType = 'update';
    this.modalStatus = true;
    this.modalElem.openModal();
  }

  drop(info) {
    // console.log(info);
  }

  resize(info) {
    // console.log(info);
  }

  onDateChange(newDate: Date) {
    this.myDateValue = newDate;
  }

  parseBoolean(val) {
    if (val === 'true') {
      return true;
    } else {
      return false;
    }
  }

  toggleVisible() {
    this.calendarVisible = !this.calendarVisible;
  }

  toggleWeekends() {
    this.calendarWeekends = !this.calendarWeekends;
  }

  gotoPast(date) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(date); // call a method on the Calendar object
  }

  handleDateClick(arg) {
    // if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
    //   this.calendarEvents = this.calendarEvents.concat({ // add new event data. must create new array
    //     title: 'New Event',
    //     start: arg.date,
    //     allDay: arg.allDay
    //   })
    // }
    // console.log(arg);
  }

}
