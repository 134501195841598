import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import * as moment from 'moment';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  user;
  info: any;
  businessHours: any;
  dataMerged: any;
  popup: any = {
    status: 0,
    title: '',
    content: ''
  };
  myDate: Date = new Date();
  mytime: Date = new Date();
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '150px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'fontName',
            'fontSize',
            'color',
            'paragraph',
            'blockquote',
            'removeBlockquote',
            'horizontalLine',
            'orderedList',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
            'redo',
            'link',
            'unlink',
            'video'
        ],
    ]
  };

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals) { }

  ngOnInit() {
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.conn
        .getWebsiteInfo()
        .subscribe((res: any) => {
          this.info = (res.data) ? res.data[0] : null;
          this.dataMerged = `${this.info.tax_deadline} ${this.info.tax_deadline_time}`;
          this.myDate = new Date(this.dataMerged);
          this.mytime = this.dataMerged;
          this.popup = {
            status: (res.data) ? res.data[0].popup_status : null ,
            title: (res.data) ? res.data[0].popup_title : null,
            content: (res.data) ? res.data[0].popup_content : null 
          };
        });
    this.conn
        .getBusinessHours()
        .subscribe((res: any) => {
          this.businessHours = res.data;
        });
  }

  update() {
    const mergedDate = `${moment.utc(this.myDate).format('YYYY-MM-DD')} ${moment.utc(this.mytime).subtract(4, 'hour').format('HH:mm:ss')}`;
    this.info.popup_content = this.popup.content;
    this.info.popup_title = this.popup.title;
    this.info.popup_status = this.popup.status;
    this.info.tax_deadline = moment(mergedDate).format('YYYY-MM-DD');
    this.info.tax_deadline_time = moment(mergedDate).format('HH:mm:ss');
    this.conn
        .updatePopupWebsite(this.info)
        .subscribe((res: any) => {
          if (res.data && res.data.affectedRows && res.data.changedRows) {
            this.global.sweetToast('success', `Popup successfully updated`);
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully changed popup info successfully`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          }
        });

    for (const iterator of this.businessHours) {
      if (iterator.day) {
        if (iterator.hour_open === 'Closed') {
          iterator.hour_close = null;
        }
        this.conn
            .updateBusinessHours(iterator, iterator.day)
            .subscribe((res: any) => {
              if (res.data && res.data.changedRows) {
                this.global.sweetToast('success', `${iterator.day} business hour successfully updated`);
                this.conn.log({
                  uid: this.user.uid,
                  createdAt: this.settingsService.currentDate(),
                  content: `User ${this.user.username} has successfully changed Business Hours (${iterator.day}) info successfully`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              }
            });
      }
    }
  }

}
