import { Component, OnInit, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { ConnectionService } from 'src/app/services/connection.service';
import { Staff } from 'src/app/classes/staff';
import { SettingsService } from 'src/app/services/settings.service';
import { Info } from 'src/app/classes/info';
import * as CryptoJS from 'crypto-js';
import { toast } from '../../../../node_modules/materialize-css';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public user: any = '';
  public forgotPassword = false;
  public info: Info;
  public submitBtn = false;

  // tslint:disable-next-line: max-line-length
  constructor(private conn: ConnectionService, private settingsService: SettingsService, private router: Router, private activatedRoute: ActivatedRoute, private cookieService: CookieService) {

    const cookieExists: boolean = this.cookieService.check('uid');
    if (cookieExists) {
      const uidCookie: string = this.cookieService.get('uid');
      this.settingsService.uid.subscribe(res => {
        if (res === uidCookie) {
          this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
        } else {
          this.cookieService.delete('uid');
        }
      });

    }
  }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    this.settingsService.getInfo().subscribe(res => {
      this.info = res.data[0];
      if (res.data[0]) {
        this.settingsService.spinner(false, 100);
      } else {
        
      }
    });
  }

  setName(el: ElementRef) {
    this.user = $(el).val();
  }

  forgotPasswordFunc() {
    if (this.forgotPassword) {
      this.forgotPassword = false;
    } else {
      this.forgotPassword = true;
    }
  }

  verifySignIn(f: NgForm) {
    if (f.form.value.password && f.form.value.username) {
      this.submitBtn = true;
    } else {
      this.submitBtn = false;
    }
  }

  toastFunc(text: string, length: number) {
    this.settingsService.uidUpdate('');
    toast({
      html: text,
      displayLength: length,
      inDuration: 300,
      outDuration: 375,
    });
  }

  toastFuncError(f: any) {
    return this.toastFunc(`There's no user linked to ${f}`, 3000);
  }


  onSubmit(f: NgForm) {
    if (this.forgotPassword) {
      if (f.form.value.email && f.form.value.email !== '') {
        this.conn.findEmail(f.form.value.email).subscribe((res: any) => {
          if (res.authorized && res.responseType === 'success') {
            if (res.data.length < 1) {
              this.toastFuncError(f);
            } else if (res.data.length === 1) {
              const selectedUser: Staff = res.data[0];
              // this.conn.sendRecoverEmail(selectedUser.email).subscribe(send => {
              //   // console.log(send);
              // });
            } else if (res.data.length > 1) {
              for (const iterator of res.data) {
                const selectedUser: Staff = iterator;
                // this.conn.sendRecoverEmail(selectedUser.email).subscribe(send => {
                //   // console.log(send);
                // });
              }
            } else {
              this.toastFuncError(this.user);
            }

          } else {
            this.toastFuncError(this.user);
          }
        });
      }
    } else {
      if (f.form.value.username && f.form.value.password) {
        const user = f.form.value.username;
        const password = CryptoJS.SHA256(f.form.value.password).toString();
        const cookieExists: boolean = this.cookieService.check('uid');
        this.conn.signin(user, password).subscribe((res: any) => {
          if (res.responseType === 'success' && res.data && res.data.length > 0) {
            const userSelected: Staff = res.data[0];
            const uidCookie: string = this.cookieService.get('uid');
            const uidHash: string = CryptoJS.SHA256(userSelected.username).toString();
            if (cookieExists) {
              if (uidCookie !== uidHash) {
                this.cookieService.delete('uid');
                this.cookieService.set( 'uid', uidHash );
              }
            } else {
              this.cookieService.set( 'uid', uidHash );
            }
            this.settingsService.uidUpdate(uidHash);
            this.settingsService.updateUserInfo(userSelected);
            this.conn.log({
              uid: userSelected.id,
              createdAt: this.settingsService.currentDate(),
              content: `User ${userSelected.username} has login successfully`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
            this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
          } else {
            this.toastFuncError(this.user);
          }

        });
      } else {
        this.toastFuncError(this.user);
      }
    }
  }

}
