import { Component, OnInit } from '@angular/core';
import { ConnectionService } from './services/connection.service';
import { SettingsService } from './services/settings.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private conn: ConnectionService, private settingsService: SettingsService, private titleService: Title) { }


  ngOnInit(): void {
    this.conn.getInfo().subscribe((res: any) => {
      this.settingsService.setInfo(res);
      this.titleService.setTitle(res.data[0].title);
    });
  }
}
