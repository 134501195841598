import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Location } from "@angular/common";
import { AfterViewInit, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { from, Observable, Observer } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { Client } from 'src/app/classes/client';
import { Globals } from 'src/app/globals.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { SettingsService } from 'src/app/services/settings.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})

export class FinanceComponent implements OnInit, OnDestroy, AfterViewInit {

  screenHeight;
  screenWidth;
  tableBodyHeight;
  user;
  transaction = {
    id: '',
    service_id: '',
    owner_id: '',
    user_id: '',
    description: '',
    amount: 0,
    created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    client_id: '',
    type: 'profit',
    status: 'processed',
    active: 1,
    payment_method: 4
  };
  services: any[] = [];
  transactions: any[] = [];
  staffs: any[] = [];
  paymentMethod: any[] = [];
  customers: any[] = [];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
        ],
     ]
  };
  type = 'add';
  showTopForm = false;
  clientSelected = '';
  myTransactions: any[] = [];
  pager: any = {};
  pagedItems: any[];
  typeSearch: Array<object> = [
    // { name: 'Service', value: 'service_id' },
    // { name: 'Employee', value: 'owner_id' },
    { name: 'Client', value: 'client_id' },
    { name: 'Phone', value: 'phone' },
    { name: 'Amount', value: 'amount' },
    { name: 'Status', value: 'status' },
    { name: 'Service', value: 'service_id' }
  ];
  dtPickerOpt;
  searchInput: any;
  typeSearchSelected = 'client_id';
  rangeA;
  rangeB;
  clientsList: any[];
  isSearchingClient = false;
  idToSearch = 0;
  selectedRecordDate = moment.utc().format('YYYY-MM-DD HH:mm:ss');
  financialInfo$: Observable<Array<object>> = new Observable;
  private chart: am4charts.XYChart;
  showGraph = 'today';
  generalInfo: any = {
    today: {
      profit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      },
      deficit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      }
    },
    week: {
      profit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      },
      deficit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      }
    },
    month: {
      profit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      },
      deficit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      }
    },
    year: {
      profit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      },
      deficit: {
        total: 0,
        card: 0,
        cash: 0,
        check: 0,
        others: 0,
      }
    },
  }
  currentDay: any = moment.utc().format('dddd | MMM DD, YYYY');
  currentWeek: any = moment.utc().format('W');
  currentMonth: any = moment.utc().format('MMMM');
  currentYear: any = moment.utc().format('YYYY');
  arrServices$: Observable<Array<object>>;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.tableBodyHeight = this.screenHeight - 295;
  }

  constructor(
    private settingsService: SettingsService,
    private conn: ConnectionService,
    private global: Globals,
    private pagination: PaginationService,
    private location: Location,
    private route: ActivatedRoute,
    private zone: NgZone
  ) {
    this.getScreenSize();
  }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    this.dtPickerOpt = this.global.datePickerOptions;
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
      this.transaction.user_id = this.user.uid;
    });
    this.tasks().then(() => setTimeout(() => {
      this.getCustomerList();
    }, 1000));
  }

  ngAfterViewInit() {
    
  }

  generateGraphToday() {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;
    
    const currentDate = moment.utc(new Date());

    chart.paddingRight = 20;
    
    let data = [];

    from(this.transactions)
    .pipe(
      filter((ts: any) => moment.utc(ts.created_at).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')),
      toArray()
    ).subscribe((res:any) => {
      // console.log(res);
      let profit = 0;
      let deficit = 0;
      for (const iterator of res) {
        let amountUpdated;
        if (iterator.type === 'profit') {
          amountUpdated = Number(iterator.amount);
          profit = profit + amountUpdated;
          if (iterator.payment_method == 1) {
            this.generalInfo.today.profit.cash = this.generalInfo.today.profit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.today.profit.card = this.generalInfo.today.profit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.today.profit.check = this.generalInfo.today.profit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.today.profit.others = this.generalInfo.today.profit.others + amountUpdated;
          }
        } else {
          amountUpdated = (-(Number(iterator.amount)));
          deficit = deficit + amountUpdated; 
          if (iterator.payment_method == 1) {
            this.generalInfo.today.deficit.cash = this.generalInfo.today.deficit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.today.deficit.card = this.generalInfo.today.deficit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.today.deficit.check = this.generalInfo.today.deficit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.today.deficit.others = this.generalInfo.today.deficit.others + amountUpdated;
          }
        }
        data.push({
          time: moment.utc(iterator.created_at).format('h A'),
          amount: amountUpdated
        });
      }
      this.generalInfo.today.profit.total = profit;
      this.generalInfo.today.deficit.total = deficit;

      // console.log(profit, this.generalInfo.today.profit);
      
    });

    chart.data = data.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);

    
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "time";
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.fontSize = 11;
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = (Math.max.apply(Math, data.map(function(o) { return o.amount; })) + 20);
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minGridDistance = 30;
    
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "time";
    series.dataFields.valueY = "amount";
    series.columns.template.tooltipText = "${valueY.value}";
    series.columns.template.tooltipY = 0;
    series.columns.template.tooltipPosition = "pointer";
    series.columns.template.strokeOpacity = 0;
    
    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
  }

  generateGraphWeek() {

    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.scrollbarX = new am4core.Scrollbar();

    let data = [];

    const currentDate = moment.utc(new Date());
    // const currentDate = moment.utc('2020-01-18'); // For test

    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('w') === currentDate.format('w') && moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      // console.log(res);
      // console.log(this.generalInfo);
      let profit = 0;
      let deficit = 0;
      let monday = 0;
      let monday_count = 0;
      let tuesday = 0;
      let tuesday_count = 0;
      let wednesday = 0;
      let wednesday_count = 0;
      let thursday = 0;
      let thursday_count = 0;
      let friday = 0;
      let friday_count = 0;
      let saturday = 0;
      let saturday_count = 0;
      let sunday = 0;
      let sunday_count = 0;
      for (const iterator of res) {
        let amountUpdated;
        if (iterator.type === 'profit') {
          amountUpdated = Number(iterator.amount);
          profit = profit + amountUpdated;
          if (iterator.payment_method == 1) {
            this.generalInfo.week.profit.cash = this.generalInfo.week.profit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.week.profit.card = this.generalInfo.week.profit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.week.profit.check = this.generalInfo.week.profit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.week.profit.others = this.generalInfo.week.profit.others + amountUpdated;
          }
        } else {
          amountUpdated = (-(Number(iterator.amount)));
          deficit = deficit + amountUpdated; 
          if (iterator.payment_method == 1) {
            this.generalInfo.week.deficit.cash = this.generalInfo.week.deficit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.week.deficit.card = this.generalInfo.week.deficit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.week.deficit.check = this.generalInfo.week.deficit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.week.deficit.others = this.generalInfo.week.deficit.others + amountUpdated;
          }
        }

        if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(1).format('YYYY-MM-DD')) {
          monday = monday + Number(iterator.amount);
          monday_count = monday_count + 1;
        } else if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(2).format('YYYY-MM-DD')) {
          tuesday = tuesday + Number(iterator.amount);
          tuesday_count = tuesday_count + 1;
        } else if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(3).format('YYYY-MM-DD')) {
          wednesday = wednesday + Number(iterator.amount);
          wednesday_count = wednesday_count + 1;
        } else if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(4).format('YYYY-MM-DD')) {
          thursday = thursday + Number(iterator.amount);
          thursday_count = thursday_count + 1;
        } else if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(5).format('YYYY-MM-DD')) {
          friday = friday + Number(iterator.amount);
          friday_count = friday_count + 1;
        } else if (moment.utc(iterator.created_at).format('YYYY-MM-DD') == currentDate.startOf('week').day(6).format('YYYY-MM-DD')) {
          saturday = saturday + Number(iterator.amount);
          saturday_count = saturday_count + 1;
        } else {
          sunday = sunday + Number(iterator.amount);
          sunday_count = sunday_count + 1;
        }

        
      }
      data.push(
        {   
          "day": `Monday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(0).brighten(0),
          "amount": monday,
          "count": monday_count
        },
        {   
          "day": `Tuesday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(2).brighten(0),
          "amount": tuesday,
          "count": tuesday_count
        },
        {   
          "day": `Wednesday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(4).brighten(0),
          "amount": wednesday,
          "count": wednesday_count
        },
        {   
          "day": `Thursday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(6).brighten(0),
          "amount": thursday,
          "count": thursday_count
        },
        {   
          "day": `Friday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(8).brighten(0),
          "amount": friday,
          "count": friday_count
        },
        {   
          "day": `Saturday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(10).brighten(0),
          "amount": saturday,
          "count": saturday_count
        },
        {   
          "day": `Sunday`,
          "start": currentDate.startOf('week').day("Sunday").format('YYYY-MM-DD'),
          "end": currentDate.startOf('week').day("Saturday").format('YYYY-MM-DD'),
          // "color": colorSet.getIndex(12).brighten(0),
          "amount": sunday,
          "count": sunday_count
        },
      );
      this.generalInfo.week.profit.total = profit;
      this.generalInfo.week.deficit.total = deficit;
    });

    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "day";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "amount";
    series.dataFields.customValue = "count";
    series.dataFields.categoryX = "day";
    series.tooltipText = "{categoryX}: [bold]${valueY}[/] ({customValue} Entries)";
    series.columns.template.strokeWidth = 0;

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;
    // hoverState.properties.tooltipText = "{categoryX}: [bold]${valueY}[/]"

    series.columns.template.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();



  }

  generateGraphMonth() {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.scrollbarX = new am4core.Scrollbar();

    const currentDate = moment.utc(new Date());
    const numberOfWeeks = Math.ceil(Number(moment.utc().endOf('month').format('DD')) / 7);
    const weeks: any = [];

    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('MMM') === currentDate.format('MMM') && moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      let profit = 0;
      let deficit = 0;
      for (let index = 0; index < numberOfWeeks; index++) {
        const element = `Week #${index + 1}`;
        weeks.push({
          id: (index + 1),
          title: element,
          amount: 0,
          start: null,
          end: null,
          count: 0,
          weekDate: null
        })
      }

      for (const iterator of res) {
        let amountUpdated;
        if (iterator.type === 'profit') {
          amountUpdated = Number(iterator.amount);
          profit = profit + amountUpdated;
          if (iterator.payment_method == 1) {
            this.generalInfo.month.profit.cash = this.generalInfo.month.profit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.month.profit.card = this.generalInfo.month.profit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.month.profit.check = this.generalInfo.month.profit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.month.profit.others = this.generalInfo.month.profit.others + amountUpdated;
          }
        } else {
          amountUpdated = (-(Number(iterator.amount)));
          deficit = deficit + amountUpdated; 
          if (iterator.payment_method == 1) {
            this.generalInfo.month.deficit.cash = this.generalInfo.month.deficit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.month.deficit.card = this.generalInfo.month.deficit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.month.deficit.check = this.generalInfo.month.deficit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.month.deficit.others = this.generalInfo.month.deficit.others + amountUpdated;
          }
        }

        if (Number(Math.ceil(moment.utc(iterator.created_at).date() / 7)) == 1) {
          weeks[0].amount = weeks[0].amount + iterator.amount;
          weeks[0].count = weeks[0].count + 1;
          if(!weeks[0].weekDate || !weeks[0].start || !weeks[1].end){
            weeks[0].start = moment.utc(iterator.created_at).startOf('week').format('DD');
            weeks[0].end = moment.utc(iterator.created_at).endOf('week').format('DD');
            weeks[0].weekDate = `${moment.utc(iterator.created_at).startOf('week').format('DD')} to ${moment.utc(iterator.created_at).endOf('week').format('DD')}`;
          }
        } else if (Number(Math.ceil(moment.utc(iterator.created_at).date() / 7)) == 2) {
          weeks[1].amount = weeks[1].amount + iterator.amount;
          weeks[1].start = moment.utc(iterator.created_at).startOf('week').format('DD');
          weeks[1].end = moment.utc(iterator.created_at).endOf('week').format('DD');
          weeks[1].count = weeks[1].count + 1;
          if(!weeks[1].weekDate || !weeks[1].start || !weeks[1].end){
            weeks[1].weekDate = `${moment.utc(iterator.created_at).startOf('week').format('DD')} to ${moment.utc(iterator.created_at).endOf('week').format('DD')}`;
          }
        } else if (Number(Math.ceil(moment.utc(iterator.created_at).date() / 7)) == 3) {
          weeks[2].amount = weeks[2].amount + iterator.amount;
          weeks[2].start = moment.utc(iterator.created_at).startOf('week').format('DD');
          weeks[2].end = moment.utc(iterator.created_at).endOf('week').format('DD');
          weeks[2].count = weeks[2].count + 1;
          if(!weeks[2].weekDate || !weeks[2].start || !weeks[2].end){
            weeks[2].weekDate = `${moment.utc(iterator.created_at).startOf('week').format('DD')} to ${moment.utc(iterator.created_at).endOf('week').format('DD')}`;
          }
        } else if (Number(Math.ceil(moment.utc(iterator.created_at).date() / 7)) == 4) {
          weeks[3].amount = weeks[3].amount + iterator.amount;
          weeks[3].start = moment.utc(iterator.created_at).startOf('week').format('DD');
          weeks[3].end = moment.utc(iterator.created_at).endOf('week').format('DD');
          weeks[3].count = weeks[3].count + 1;
          if(!weeks[3].weekDate || !weeks[3].start || !weeks[3].end){
            weeks[3].weekDate = `${moment.utc(iterator.created_at).startOf('week').format('DD')} to ${moment.utc(iterator.created_at).endOf('week').format('DD')}`;
          }
        } else if (Number(Math.ceil(moment.utc(iterator.created_at).date() / 7)) == 5) {
          weeks[4].amount = weeks[4].amount + iterator.amount;
          weeks[4].start = moment.utc(iterator.created_at).startOf('week').format('DD');
          weeks[4].end = moment.utc(iterator.created_at).endOf('week').format('DD');
          weeks[4].count = weeks[4].count + 1;
          if(!weeks[4].weekDate || !weeks[4].start || !weeks[4].end){
            weeks[4].weekDate = `${moment.utc(iterator.created_at).startOf('week').format('DD')} to ${moment.utc(iterator.created_at).endOf('week').format('DD')}`;
          }
        } else {
          console.log("Error");
        }


        // data.push({
        //   time: moment.utc(iterator.created_at).format('h A'),
        //   amount: amountUpdated
        // });
      }

      
      this.generalInfo.month.profit.total = profit;
      this.generalInfo.month.deficit.total = deficit;
    });
    // console.log(weeks);

    chart.data = weeks;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "title";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "amount";
    series.dataFields.customValue = "count";
    series.dataFields.categoryX = "title";
    series.dataFields.categoryY = "weekDate";
    series.tooltipText = "{categoryX}: [bold]${valueY}[/] ({customValue} Entries)";
    series.columns.template.strokeWidth = 0;

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;
    // hoverState.properties.tooltipText = "{categoryX}: [bold]${valueY}[/]"

    series.columns.template.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }

  generateGraphYear() {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.scrollbarX = new am4core.Scrollbar();
    const currentDate = moment.utc(new Date());
    let profit = 0;
    let deficit = 0;
    let data = [];
    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      
      for (let index = 0; index < 12; index++) {
        const element = moment.utc().month((index));
        data.push({
          id: (index + 1),
          title: element.format('MMMM'),
          amount: 0,
          start: element.startOf('day').format('DD'),
          end: element.endOf('day').format('DD'),
          count: 0,
        })
      }

      for (const iterator of res) {
        let amountUpdated;

        if (iterator.type === 'profit') {
          amountUpdated = Number(iterator.amount);
          profit = profit + amountUpdated;
          if (iterator.payment_method == 1) {
            this.generalInfo.year.profit.cash = this.generalInfo.year.profit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.year.profit.card = this.generalInfo.year.profit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.year.profit.check = this.generalInfo.year.profit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.year.profit.others = this.generalInfo.year.profit.others + amountUpdated;
          }
        } else {
          amountUpdated = (-(Number(iterator.amount)));
          deficit = deficit + amountUpdated; 
          if (iterator.payment_method == 1) {
            this.generalInfo.year.deficit.cash = this.generalInfo.year.deficit.cash + amountUpdated;
          }
          if (iterator.payment_method == 2) {
            this.generalInfo.year.deficit.card = this.generalInfo.year.deficit.card + amountUpdated;
          }
          if (iterator.payment_method == 3) {
            this.generalInfo.year.deficit.check = this.generalInfo.year.deficit.check + amountUpdated;
          }
          if (iterator.payment_method == 4) {
            this.generalInfo.year.deficit.others = this.generalInfo.year.deficit.others + amountUpdated;
          }
        }

        if (Number(moment.utc(iterator.created_at).format('M')) == 1 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[0].amount = data[0].amount + iterator.amount;
          data[0].count = data[0].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 2 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[1].amount = data[1].amount + iterator.amount;
          data[1].count = data[1].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 3 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[2].amount = data[2].amount + iterator.amount;
          data[2].count = data[2].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 4 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[3].amount = data[3].amount + iterator.amount;
          data[3].count = data[3].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 5 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[4].amount = data[4].amount + iterator.amount;
          data[4].count = data[4].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 6 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[5].amount = data[5].amount + iterator.amount;
          data[5].count = data[5].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 7 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[6].amount = data[6].amount + iterator.amount;
          data[6].count = data[6].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 8 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[7].amount = data[7].amount + iterator.amount;
          data[7].count = data[7].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 9 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[8].amount = data[8].amount + iterator.amount;
          data[8].count = data[8].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 10 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[9].amount = data[9].amount + iterator.amount;
          data[9].count = data[9].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 11 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[10].amount = data[10].amount + iterator.amount;
          data[10].count = data[10].count + 1;
        } else if (Number(moment.utc(iterator.created_at).format('M')) == 12 && moment.utc(iterator.created_at).format('YYYY') == currentDate.format('YYYY')) {
          data[11].amount = data[11].amount + iterator.amount;
          data[11].count = data[11].count + 1;
        }

      }

      this.generalInfo.year.profit.total = profit;
      this.generalInfo.year.deficit.total = deficit;
    });

    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "title";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "amount";
    series.dataFields.customValue = "count";
    series.dataFields.categoryX = "title";
    series.dataFields.categoryY = "weekDate";
    series.tooltipText = "{categoryX}: [bold]${valueY}[/] ({customValue} Entries)";
    series.columns.template.strokeWidth = 0;

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;
    // hoverState.properties.tooltipText = "{categoryX}: [bold]${valueY}[/]"

    series.columns.template.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }

  cleanInfo() {
    this.generalInfo = {
      today: {
        profit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        },
        deficit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        }
      },
      week: {
        profit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        },
        deficit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        }
      },
      month: {
        profit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        },
        deficit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        }
      },
      year: {
        profit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        },
        deficit: {
          total: 0,
          card: 0,
          cash: 0,
          check: 0,
          others: 0,
        }
      },
    }
  }

  generateGraph(type?:string) {
    this.cleanInfo();
    let newType;
    if (type) {
      newType = type.toLowerCase();
      this.showGraph = newType;
    } else {
      newType = this.showGraph;
    }
    // console.log(newType, this.showGraph);
    this.zone.runOutsideAngular(() => {
      this.ngOnDestroy();
      
      if (newType === 'today') {
        this.generateGraphToday();
        this.showGraph = 'today';
      } else if (newType === 'week') {
        this.showGraph = 'week';
        this.generateGraphWeek();
      } else if (newType === 'month') {
        this.showGraph = 'month';
        this.generateGraphMonth();
      } else if (newType === 'year') {
        this.showGraph = 'year';
        this.generateGraphYear();
      } else {
        this.generateGraphToday();
      }
    
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  async tasks() {
    await this.getPaymentMethodList();
    await this.getServiceList();
    await this.getStaffList();
  }

  getServiceList() {
    this.conn
        .getServices()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(filter((service: any) => service.active == 1), toArray())
          .subscribe(servicesArr => this.services = servicesArr.sort());
        });
  }
  getStaffList() {
    this.conn
        .getStaff()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((staffs: any) => ({
              id: staffs.id,
              name: staffs.name,
              access_status: staffs.access_status,
              level: this.settingsService.convertLevel(staffs.access_level),
            })),
            filter((staff: any) => staff.access_status === 'active'),
            toArray()
            )
          .subscribe(staffArr => this.staffs = staffArr.sort());
        });
  }

  getTransactionList() {
    this.conn
        .getTransaction()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((transaction: any) => ({
              ...transaction,
              amount: Number(transaction.amount),
              serviceObj: this.services.find(service => service.id == transaction.service_id),
              clientObj: this.customers.find(customer => customer.id == transaction.client_id),
              ownerObj: this.staffs.find(staff => staff.id == transaction.owner_id),
              createdBy: this.staffs.find(createdBy => createdBy.id == transaction.user_id),
              paymentObj: this.paymentMethod.find(payment => payment.id == transaction.payment_method)
          })),
          filter((myT: any) => myT.active === 1),
          toArray()
          ).subscribe(srvArr => {
            this.transactions = srvArr
            this.generateFinancialInfo();
          });

            if (this.user.convertedLevel === 1) {
              from(this.transactions)
              .pipe(filter((myT: any) => myT.owner_id === this.user.uid || myT.status === 'pending'), toArray())
              .subscribe(selectedTransactions => this.myTransactions = selectedTransactions.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1));
            } else {
              if (this.user.convertedLevel > 3) {
                this.myTransactions = this.transactions.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
              } else {
              from(this.transactions)
              .pipe(filter((myT: any) => myT.ownerObj.level <= this.user.convertedLevel), toArray())
              .subscribe(selectedTransactions => this.myTransactions = selectedTransactions.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1));
              }
            }
            // console.log(this.myTransactions);
            this.setPage(1);
            this.route.queryParams.subscribe(params => {
              const passedId: string = params['id'];
              if (passedId) {
                from(this.transactions)
                .pipe(
                  filter((tr: any) => tr.id == passedId)
                ).subscribe((res: any) => {
                  this.actionUpdate(res);
                  this.location.replaceState('finance');
                });
              }
            });
            this.settingsService.spinner(false, 2000);
          });
  }

  getCustomerList() {
    this.conn
        .getCustomers()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((client: any) => ({
              id: client.id,
              name: client.name,
              access_status: client.access_status
            })),
            filter((clientObj: any) => clientObj.access_status === 'active'),
            toArray()
          )
          .subscribe(clientArr => {
              const newArr = clientArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.customers = newArr;
              setTimeout(() => {
                this.getTransactionList();
              }, 1000);
          });
        });
  }

  getPaymentMethodList() {
    this.conn
        .getPaymentMethod()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((payment: any) => ({
              id: payment.id,
              title: payment.title,
              active: payment.active
            })),
            filter((paymentObj: any) => paymentObj.active === 1),
            toArray()
          )
          .subscribe(paymentArr => {
              const newArr = paymentArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.paymentMethod = newArr;
          });
        });
  }

  onSelect(event: TypeaheadMatch): void {
    this.transaction.client_id = event.item.id;
  }

  actionAdd() {
    this.type = 'add';
    this.transaction = {
      id: '',
      service_id: '',
      owner_id: '',
      user_id: this.user.uid,
      description: '',
      amount: 0,
      created_at: this.selectedRecordDate,
      client_id: '',
      type: '',
      status: 'processed',
      active: 1,
      payment_method: 1
    };
    delete this.transaction.id;
    this.showTopForm = true;
    this.clientSelected = '';
  }

  actionUpdate(item: any) {
    this.type = 'update';
    this.clientSelected = item.clientObj.name;
    this.transaction = item;
    this.showTopForm = true;
  }

  setTransactionType() {
    if (this.transaction.amount > 0) {
      this.transaction.type = 'profit';
    } else {
      this.transaction.type = 'deficit';
    }
  }

  dismissAdd() {
    this.actionAdd();
    this.showTopForm = false;
  }

  add() {
    this.setTransactionType();
    this.transaction.created_at = `${moment.utc(this.selectedRecordDate).format('YYYY-MM-DD')} ${moment.utc(new Date()).format('HH:mm:ss')}`;
    
    if (this.transaction &&
        (this.transaction.amount !== 0 &&
        this.transaction.service_id !== '' &&
        this.transaction.owner_id !== '' &&
        this.transaction.client_id !== '' &&
        this.transaction.type !== '' &&
        this.transaction.status !== '')) {

      this.conn.addData(this.transaction, 'erp_finance').subscribe((res: any) => {
        if (res.data && res.data.affectedRows > 0) {
          if (res.data.insertId) {
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully added transaction ID #${res.data.insertId}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
              this.conn.log({
                uid: this.user.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.user.username} has successfully added a transaction`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
          }
          this.actionAdd();
          this.getTransactionList();
        } else {
          this.global.sweetToast('error', 'Transaction was not added');
        }
      });
    } else {
      this.global.sweetToast('error', 'Client should have SERVICE, EMPLOYEE, CLIENT, TYPE, STATUS and AMOUNT');
    }
  }

  update() {
    this.setTransactionType();
    const id = this.transaction.id;
    const transaction: any = this.transaction;
    delete transaction.id;
    delete transaction.clientObj;
    delete transaction.serviceObj;
    delete transaction.ownerObj;
    delete transaction.createdBy;
    delete transaction.paymentObj;

    this.conn.updateData(transaction, id, 'erp_finance').subscribe(async (res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.global.sweetToast('success', `Transaction ${id} was successfully updated`);
          this.getTransactionList();
          this.showTopForm = false;
          this.conn.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `Transaction was successfully updated by ${this.user.name}. Transaction ID #${id}`
          }).subscribe(log => {
            // console.log('Log added successfully');
          });
        } else {
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.global.sweetToast('error', 'Sorry! transaction was not updated. Please try again');
      }
    });
  }

  delete() {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    const transaction = {
      active: 0
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        const id = this.transaction.id;
        const amount = this.transaction.amount;
        const transactionDate = this.transaction.created_at;
        this.conn.deleteData(id, 'erp_finance').subscribe(async (res: any) => {
          if (res.data && (res.data.affectedRows > 0 || res.data.changedRows > 0)) {
            this.global.sweetToast('success', `Transaction ${id} was successfully deleted`);
            this.getTransactionList();
            this.showTopForm = false;
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `Transaction was successfully inactivated by ${this.user.name}. Transaction ID #${id}, Amount: $${amount} on ${transactionDate}`
            }).subscribe(log => {
              // console.log('Log added successfully');
            });
          } else {
            this.global.sweetToast('error', 'Sorry! transaction was not deleted. Please try again');
          }
        });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });
  }

  getSearchClient(event) {
    this.clientsList = [];
    const input = event.target.value;
    this.idToSearch = 0;
    if (input) {
      let field;
      if (Number(input)) {
        field = 'phone_primary';
      } else {
        field = 'name';
      }
      this.conn
        .searchClient(input, field)
        .subscribe((clients: any) => {
          from(clients.data)
          .pipe(
              map((client: Client) => ({
                name: client.name,
                id: client.id
              })), toArray()
            )
            .subscribe((clientsList) => {
              if (clientsList.length) {
                this.clientsList = clientsList.sort();
                this.isSearchingClient = true;
              } else {
                this.isSearchingClient = false;
              }
            });
      });
    }
  }

  clearInput(model) {
    if (model === 'inputSearch') {
      this.searchInput = '';
    }
    if (model === 'rangeA') {
      this.rangeA = '';
    }
    if (model === 'rangeB') {
      this.rangeB = '';
    }
  }

  setIdToSearch(id, name) {
    this.idToSearch = id;
    this.searchInput = name;
    this.isSearchingClient = false;
  }
  
  onChangeSearchBy(event) {
    this.searchInput = '';
    this.clientsList = [];
    // console.log(event);
    if (event.target.value == 'service_id') {
      const arr = [];
      for (const iterator of this.myTransactions) {
        arr.push({
          title: iterator.serviceObj.title,
          service_id:iterator.service_id
        });
      }
      this.arrServices$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        const next = arr.reduce((acc, current) => {
          const x = acc.find(item => item.title === current.title);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        observer.next(next.sort((a, b) => (a.title > b.title) ? 1 : -1));
      })
      
      // this.arrServices$.subscribe(console.log);
    }
  }

  private async searchByPhone (phone, field) { 
    return await this.conn.searchClient( phone, field ).toPromise();
  }

  async search() {

    if (this.typeSearchSelected && this.searchInput) {
      // console.log(this.typeSearchSelected);
      
      this.myTransactions = [];
      let input = this.searchInput;
      if ( this.typeSearchSelected == 'client_id' ) {
        input = this.idToSearch;
        this.isSearchingClient = true;
      } else if ( this.typeSearchSelected == 'amount' ) {
        this.isSearchingClient = false;
        this.clientsList = [];
        this.searchInput = '';
      } else if ( this.typeSearchSelected == 'status' ) {
        this.isSearchingClient = false;
        this.clientsList = [];
        if ( this.searchInput == 'pending' ) {
          input = 'pending';
        } else if ( this.searchInput == 'processed' ) {
          input = 'processed';
        } else {
          this.searchInput = '';
          input = this.searchInput;
          this.clientsList = [];
          this.global.sweetToast( 'error', `Search field should have either 'pending' or 'processed' value` );
        }
      } else if ( this.typeSearchSelected == 'service_id' ) {
        this.isSearchingClient = false;
        this.clientsList = [];
        input = this.searchInput;
      } else if ( this.typeSearchSelected == 'phone' ) {
        this.isSearchingClient = true;
        this.clientsList = [];
        await this.searchByPhone( this.searchInput, 'phone_primary' )
          .then( async ( firstSearch: any ) => {
            if ( 'data' in firstSearch && firstSearch.data.length ) {
              input = await firstSearch.data[ 0 ].id;
              this.searchInput = '';
            } else {
              await this.searchByPhone( this.searchInput, 'phone_secondary' )
                .then( async ( secondSearch: any ) => {                   
                  if ( 'data' in secondSearch && secondSearch.data.length ) {
                    input =  await secondSearch.data[ 0 ].id;
                    this.searchInput = '';
                  }
                })
            }
          } );        
      } else {
        this.global.sweetToast('error', `Search By field should be selected`);
      }

      if (this.rangeA) {
        this.rangeA = moment.utc(this.rangeA).format('YYYY-MM-DD');
      } else {
        this.rangeA = '';
      }

      if (this.rangeB) {
        this.rangeB = moment.utc(this.rangeB).format('YYYY-MM-DD');
      } else {
        this.rangeB = '';
      }

      if (input && this.typeSearchSelected) {
        const typeSearchSelected = ( this.typeSearchSelected === 'phone' ) ? 'client_id' : this.typeSearchSelected;        
        this.conn
            .searchTransaction(input, typeSearchSelected, this.rangeA, this.rangeB)
            .subscribe((transactions: any) => {
              if (this.user.convertedLevel > 3) {
                from(transactions.data)
                .pipe(
                  map((transaction: any) => ({
                    ...transaction,
                    amount: Number(transaction.amount),
                    serviceObj: this.services.find(service => service.id == transaction.service_id),
                    clientObj: this.customers.find(customer => customer.id == transaction.client_id),
                    ownerObj: this.staffs.find(staff => staff.id == transaction.owner_id),
                    createdBy: this.staffs.find(createdBy => createdBy.id == transaction.user_id)
                  })),
                  // filter((trs: any) => trs.id == 1),
                  toArray()
                )
                .subscribe(srvArr => {
                  this.myTransactions = srvArr.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1)
                });
              } else {
                from(transactions.data)
                .pipe(
                  map((transaction: any) => ({
                      ...transaction,
                      amount: Number(transaction.amount),
                      serviceObj: this.services.find(service => service.id == transaction.service_id),
                      clientObj: this.customers.find(customer => customer.id == transaction.client_id),
                      ownerObj: this.staffs.find(staff => staff.id == transaction.owner_id),
                      createdBy: this.staffs.find(createdBy => createdBy.id == transaction.user_id)
                  })),
                  filter((transaction: any) => transaction.owner_id == this.user.uid || transaction.status == 'pending'),
                  toArray())
                .subscribe(srvArr => this.myTransactions = srvArr.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1));
              }
              this.setPage(1);
            });            
      }

    } else {
      this.getTransactionList();
      this.global.sweetToast('error', `Fields SEARCH and SEARCH BY should be filled out.`);
    }

  }

  setPage(page: number) {
    let pageSize;
    if (this.screenHeight > 1024) {
      pageSize = 13;
    }

    const newArr = this.myTransactions;

    // get pager object from service
    this.pager = this.pagination.getPager(newArr.length, page, pageSize, 10);

    // get current page of items
    this.pagedItems = newArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // console.log(this.pagedItems);
    
  }

  numbersOnly(event): boolean {
    return this.global.numberOnly(event);
  }

  resetData() {  
    this.searchInput = '';
    this.getTransactionList();
  }

  generateFinancialInfo() {
    const currentDate = moment.utc(new Date());
    this.financialInfo$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
      observer.next([]);
    })
    /**
     * Today
     */
    from(this.transactions)
    .pipe(
      filter((ts: any) => moment.utc(ts.created_at).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')),
      toArray()
    ).subscribe((res:any) => {
      let item: Array<object> = [];
      this.financialInfo$.subscribe(res => {
        // console.log(res);
        if (res.length) {
          item = res;
        }
      })
      const data: any = {};
      data.amount = res.reduce((sum, item) => sum + item.amount, 0);
      data.date = moment.utc().format('dddd | MMM DD, YYYY');
      data.title = 'Earnings today';
      data.type = 'Today';
      item.push(data);
      this.financialInfo$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        observer.next(item);
      })
      this.currentDay = `${moment.utc().format('dddd | MMM DD, YYYY')}`;
      // console.log(data, moment.now(), moment.utc().format('dddd | MMM DD, YYYY'));
    });
    
    /**
     * This Week
     */

    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('w') === currentDate.format('w') && moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      let item: Array<object> = [];
      this.financialInfo$.subscribe(res => {
        // console.log(res);
        if (res.length) {
          item = res;
        }
      })
      const data: any = {};
      data.amount = res.reduce((sum, item) => sum + item.amount, 0);
      data.date = `${moment.utc().day("Sunday").week(Number(currentDate.format('w'))).format('MMM DD')} to ${moment.utc().day("Saturday").week(Number(currentDate.format('w'))).format('MMM DD')}`;
      data.title = `Week: ${currentDate.format('w')} of 52`;
      data.type = 'Week';
      item.push(data);
      this.financialInfo$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        observer.next(item);
      })
      this.currentWeek = `${currentDate.format('w')} (${moment.utc().day("Sunday").week(Number(currentDate.format('w'))).format('DD')} to ${moment.utc().day("Saturday").week(Number(currentDate.format('w'))).format('DD')})`;
      // console.log(data);
    });
    
    /**
     * This Month
     */

    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('MMM') === currentDate.format('MMM') && moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      let item: Array<object> = [];
      this.financialInfo$.subscribe(res => {
        // console.log(res);
        if (res.length) {
          item = res;
        }
      })
      const data: any = {};
      data.amount = res.reduce((sum, item) => sum + item.amount, 0);
      data.date = `${moment.utc().startOf('month').format('MMMM, Do')} to ${moment.utc().endOf('month').format('Do')}`;
      data.title = `This month: ${currentDate.format('MMM, YYYY')}`;
      data.type = 'Month';
      item.push(data);
      this.financialInfo$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        observer.next(item);
      })
      this.currentMonth = moment.utc().format('MMMM');
      // console.log(data);
    });
    
    /**
     * This Year
     */

    from(this.transactions)
    .pipe(
      filter((ts: any) => (moment.utc(ts.created_at).format('YYYY') === currentDate.format('YYYY'))),
      toArray()
    ).subscribe((res:any) => {
      let item: Array<object> = [];
      this.financialInfo$.subscribe(res => {
        // console.log(res);
        if (res.length) {
          item = res;
        }
      })
      const data: any = {};
      data.amount = res.reduce((sum, item) => sum + item.amount, 0);
      data.date = `Day ${moment.utc().format('DDD')} of ${moment.utc().endOf('year').format('DDD')} ${(Number(moment.utc().endOf('year').format('DDD')) === 366) ? '(Leap year)' : null}`;
      data.title = `This year: ${currentDate.format('YYYY')}`;
      data.type = 'Year';
      item.push(data);
      this.financialInfo$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
        observer.next(item);
      })
      // console.log(data);
    });

    setTimeout(() => {
      if (this.user.convertedLevel > 3) {
        this.refreshGraph();
      }
    }, 1000);
  }

  refreshGraph() {
    this.ngOnDestroy();
    setTimeout(() => {
      if (this.user.convertedLevel > 3) {
        this.generateGraph();
      }
    }, 1000);
  }

}
