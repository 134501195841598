import { Component, OnInit, HostListener } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { Client } from 'src/app/classes/client';
import { from } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  user;
  screenWidth;
  pager: any = {};
  pagedItems: any[];
  customers;
  searchInput;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private settingsService: SettingsService,
    private conn: ConnectionService,
    private global: Globals,
    private pagination: PaginationService) {
      this.getScreenSize();
  }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.getCustomerList();
  }

  setPage(page?: number, data?: Array<object>) {
    let pageSize = 16;
    let pageQuantity = 10;
    if (this.screenWidth < 425) {
      pageSize = 4;
      pageQuantity = 1;
    } else if (this.screenWidth < 768) {
      pageSize = 6;
      pageQuantity = 2;
    } else if (this.screenWidth < 1024) {
      pageSize = 9;
      pageQuantity = 5;
    } else if (this.screenWidth < 1200) {
      pageSize = 9;
      pageQuantity = 8;
    } else if (this.screenWidth < 1440) {
      pageSize = 12;
      pageQuantity = 10;
    } else {
      pageSize = 16;
      pageQuantity = 10;
    }

    let newArr;

    if (data) {
      newArr = data;
    } else {
      newArr = this.customers;
    }

    let pageStart;

    if (page) {
      pageStart = page;
    } else {
      pageStart = 1;
    }

    // get pager object from service
    this.pager = this.pagination.getPager(newArr.length, pageStart, pageSize, pageQuantity);

    // get current page of items
    this.pagedItems = newArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // console.log(this.pagedItems);
    
  }

  getCustomerList() {
    this.conn
        .getCustomers()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            filter((clientObj: Client) => clientObj.access_status === 'active'),
            map((client: Client) => ({
              id: client.id,
              name: client.name,
              access_status: client.access_status,
              phone_primary: client.phone_primary,
              phone_secondary: client.phone_secondary,
              email: client.email
            })),
            toArray()
          )
          .subscribe(clientArr => {
              const newArr = clientArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.customers = newArr;
              this.setPage(1);
          });
        });
  }

  search() {
    // console.log(this.searchInput);
    this.conn
        .searchData(this.searchInput, 'erp_customer', 'name')
        .subscribe((res: any) => {
          // console.log(res);
          this.setPage(1, res.data);
        });
  }

}
