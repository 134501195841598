import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'googlemaps';
import { Observable, Observer } from 'rxjs';
import { Globals } from 'src/app/globals.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { SettingsService } from 'src/app/services/settings.service';
import Swal from '../../../../node_modules/sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  users$: Observable<any>;
  services$: Observable<any>;
  filter = new FormControl('');
  userInfo = {} as any;
  currentlyPass = '' as any;
  user = {} as any;
  service = {} as any;
  userFullAddress = '' as any;
  userAccessStatus = '' as any;
  userAccessLevel = '' as any;
  changePassword = false;
  passwordVisible = false;
  newPassword = '' as string;
  passMatch = false;
  retryPassword = '' as string;
  isNewReg = true;
  isNewService = true;

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals) { }

  ngOnInit() {
    // this.settingsService.checkCookie();
    this.settingsService
        .checkLogin();
    this.settingsService
        .userInfo
        .subscribe((res: any) => {
          this.userInfo = res;
        });
    this.getStaff();
    this.getServices();
  }

  export(list) {
    this.sweetConfirmPassword(list);
  }

  sweetConfirmPassword(list) {
    Swal.fire({
      title: 'Type your password',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Verify',
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        return this.conn
        .signin(this.userInfo.email, this.settingsService.encryptSHA256(password))
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: any) => {
      result.value.subscribe((res: any) => {
        if (res.data.length) {
          if (list == 'client') {
            this.conn
                .getCustomers()
                .subscribe((res: any) => {
                  this.settingsService
                      .exportAsExcelFile(res.data, 'clients');
                })
          }
          
        } else {
          Swal.fire({
            title: `Oops! Password is wrong!`
          })
        }

      })
      
    })
  }

  getStaff() {
    this.conn
        .getStaff()
        .subscribe((res: any) => {
          const newArr = res.data.sort((a , b) => 0 - (a.name > b.name ? -1 : 1));
          this.isNewReg = true;
          this.user = {};
          this.users$ = new Observable<object[]>((observer: Observer<object[]>) => {
            observer.next(newArr);
            this.setUserConfig();
          });
        });
  }
  
  getServices() {
    this.conn
        .getServices()
        .subscribe((res: any) => {
          const newArr = res.data.sort((a , b) => 0 - (a.title > b.title ? -1 : 1));
          this.isNewReg = true;
          this.service = {};
          this.services$ = new Observable<object[]>((observer: Observer<object[]>) => {
            observer.next(newArr);
          });
        });
  }

  loadEmployee(item) {
    this.isNewReg = false;
    this.user = item;
    this.setUserConfig();
    // console.log(this.user);
  }
  
  loadService(item) {
    this.isNewService = false;
    this.service = item;
    // this.setUserConfig();
    // console.log(this.service);
  }

  setUserConfig() {
    this.currentlyPass = this.user.password;
    this.userAccessStatus = this.user.access_status;
    this.userAccessLevel = this.user.access_level;
    this.userFullAddress = this.settingsService.fullAddress(this.user);
  }

  updateUsername() {
    this.user.username = this.user.email;
  }

  searchPlace(input) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert(`No details available for input: ${place.name}`);
        return;
      }
      let address = '';
      if (place.address_components) {
        if (place.address_components[0] && (place.address_components[0].short_name || place.address_components[0].long_name)) {
          if (place.address_components[0].long_name) {
            address = place.address_components[0].long_name;
            this.user.address_number = place.address_components[0].long_name;
          } else {
            address = place.address_components[0].short_name;
            this.user.address_number = place.address_components[0].short_name;
          }
        }
        if (place.address_components[1] && (place.address_components[1].short_name || place.address_components[1].long_name)) {
          if (place.address_components[1].long_name) {
            address += ` ${place.address_components[1].long_name}`;
            this.user.address_street = place.address_components[1].long_name;
          } else {
            address += ` ${place.address_components[1].short_name}`;
            this.user.address_street = place.address_components[1].short_name;
          }
        }
        if (place.address_components[2] && (place.address_components[2].short_name || place.address_components[2].long_name)) {
          if (place.address_components[2].long_name) {
            address += `, ${place.address_components[2].long_name}`;
            this.user.address_city = place.address_components[2].long_name;
          } else {
            address += `, ${place.address_components[2].short_name}`;
            this.user.address_city = place.address_components[2].short_name;
          }
        }
        if (place.address_components[3] && (place.address_components[3].short_name || place.address_components[3].long_name)) {
          if (place.address_components[3].long_name) {
            address += `, ${place.address_components[3].long_name}`;
            this.user.address_county = place.address_components[3].long_name;
          } else {
            address += `, ${place.address_components[3].short_name}`;
            this.user.address_county = place.address_components[3].short_name;
          }
        }
        if (place.address_components[4] && (place.address_components[4].short_name || place.address_components[4].long_name)) {
          if (place.address_components[4].long_name) {
            address += `, ${place.address_components[4].short_name}`;
            this.user.address_state = place.address_components[4].short_name;
          } else {
            address += `, ${place.address_components[4].long_name}`;
            this.user.address_state = place.address_components[4].long_name;
          }
        }
        if (place.address_components[5] && (place.address_components[5].short_name || place.address_components[5].long_name)) {
          if (place.address_components[5].long_name) {
            address += `, ${place.address_components[5].short_name}`;
            this.user.address_country = place.address_components[5].short_name;
          } else {
            address += `, ${place.address_components[5].long_name}`;
            this.user.address_country = place.address_components[5].long_name;
          }
        }
        if (place.address_components[6] && (place.address_components[6].short_name || place.address_components[6].long_name)) {
          if (place.address_components[6].long_name) {
            address += ` ${place.address_components[6].short_name}`;
            this.user.address_zipcode = place.address_components[6].short_name;
          } else {
            address += ` ${place.address_components[6].long_name}`;
            this.user.address_zipcode = place.address_components[6].long_name;
          }
        }
        if (place.address_components[7] && (place.address_components[7].short_name || place.address_components[7].long_name)) {
          if (place.address_components[7].long_name) {
            address += `-${place.address_components[7].short_name}`;
          } else {
            address += `-${place.address_components[7].long_name}`;
          }
        }
      }
      this.userFullAddress = address;
    });
  }

  onSelect(event: { target: { checked: boolean; }; }) {
    this.changePassword = event.target.checked;
  }

  passwordVisibility() {
    if (this.passwordVisible) {
      this.passwordVisible = false;
    } else {
      this.passwordVisible = true;
    }
  }

  checkPassword() {
    if (this.retryPassword === this.newPassword && (this.newPassword !== '')) {
      this.passMatch = true;
    } else {
      this.passMatch = false;
    }
  }

  update() {
    const uid = this.user.id;
    const pass = this.user.password;
    const createdAt = this.user.created_at;
    const updatedAt = this.user.updated_at;
    const employee  = this.user;
    delete employee.id;
    delete employee.created_at;
    delete employee.updated_at;
    if (this.changePassword) {
      if (this.passMatch) {
        employee.password = this.settingsService.encryptSHA256(this.newPassword);
        this.updateData(employee, uid);
      } else {
        this.global.sweetToast('error', 'Sorry! User was not updated. Please try check if password is typed correctly');
      }
    } else {
      delete employee.password;
      this.updateData(employee, uid);
      employee.password = pass;
    }
    employee.id = uid;
    employee.created_at = createdAt;
    employee.updated_at = updatedAt;
  }

  search() {
    this.settingsService.searchTable('search', 'searchable');
  }
  
  searchService() {
    this.settingsService.searchTable('searchService', 'searchableService');
  }

  updateData(data, id) {
    this.conn.updateData(data, id, 'erp_staff').subscribe(async (res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.global.sweetToast('success', `User ${data.name} was successfully updated`);
          this.conn.log({
            uid: this.userInfo.uid,
            createdAt: this.settingsService.currentDate(),
            content: `User ${data.name} was successfully updated. User ID #${id}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
        } else {
          this.global.sweetToast('warning', 'No changes were detected');
        }
      } else {
        this.global.sweetToast('warning', 'User was not updated! No changes were detected');
      }
    });
  }

  delete() {
    const employee = this.user;
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        this.conn.deleteData(employee.id, 'erp_staff').subscribe((res: any) => {
          if (res.data && res.data.affectedRows > 0) {
            this.getStaff();
            this.global.sweetToast('success', 'Employee was successfully deleted');
            this.conn.log({
              uid: this.userInfo.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.userInfo.name} has successfully deleted employee #${employee.id}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
            this.global.sweetToast('error', 'Sorry! Employee was not deleted. Please try again');
          }
        });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });

  }

  saveMode() {
    this.isNewReg = true;
    this.userFullAddress = '';
    this.user = {};
  }
  
  saveModeService() {
    this.isNewService = true;
    this.service = {};
  }

  add() {
    const employee = this.user;
    if (this.changePassword) {
      employee.password = this.newPassword;
    }
    if ( employee.username && employee.password && employee.name && employee.access_status && employee.access_level ) {
      employee.password = this.settingsService.encryptSHA256( employee.password );
      employee.username = employee.username.toLowerCase();
      employee.email = employee.email.toLowerCase();
      
      this.conn.addData(employee, 'erp_staff').subscribe((res: any) => {
        if (res.data && res.data.affectedRows > 0) {
          this.global.sweetToast('success', 'Employee was added');
          if (res.data.insertId) {
            this.conn.log({
              uid: this.userInfo.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.userInfo.name} has successfully added a new employee - ID #${res.data.insertId}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
              this.conn.log({
                uid: this.userInfo.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.userInfo.username} has successfully added a new employee`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
          }
          this.newPassword = '';
          this.retryPassword = '';
          this.changePassword = false;
          this.getStaff();
        } else {
          this.global.sweetToast('error', 'Employee was not added');
        }
      });
    } else {
      this.global.sweetToast('error', 'Employee was not added. Check all required fields');
    }
  }

  addService() {
    
    if (!this.service.content) {
      this.service.content = null;
    }

    const data = {
      id: null,
      title: this.service.title,
      active: '1',
      content: this.service.content,
      img: null
    };

    if (this.service.title) {
      this.conn
          .addDataValues(data, 'erp_service')
          .subscribe((res: any) => {
            if (res.data && res.data.affectedRows > 0) {
              this.global.sweetToast('success', 'Services was successfully added');
              if (res.data.insertId) {
                this.conn.log({
                  uid: (this.user.id ? this.user.id : this.user.uid),
                  createdAt: this.settingsService.currentDate(),
                  content: `User ${this.user.username} has successfully added service ID #${res.data.insertId}`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              } else {
                  this.conn.log({
                    uid: (this.user.id ? this.user.id : this.user.uid),
                    createdAt: this.settingsService.currentDate(),
                    content: `User ${this.user.username} has successfully added a service`
                  }).subscribe(log => {
                    // console.log('Login successfully');
                  });
              }
              this.getServices();
              this.service = {};
              this.isNewService = true;
              
            } else {
              this.global.sweetToast('error', 'Service was not added');
            }
          });
    }
    
  }

  deleteService() {
    if (!this.service.id) {
      this.global.sweetToast('error', 'Error: ID is missing');
    } else {
      const title = this.service.title;
      const id = this.service.id;
      

      const data = {
        title: 'Attention',
        text: 'This will be permanently deleted. Are you sure about this action?',
        type: 'warning',
        cancelButton: true,
        confirmText: 'Delete it!'
      };

      this.global.sweetAlert(data).then((result: { value: any; }) => {
        if (result.value) {
          this.conn
          .deleteData(this.service.id, 'erp_service')
          .subscribe((res: any) => {
            if (res.data && res.data.affectedRows > 0) {
              this.global.sweetToast('success', `Service '${title}' was successfully deleted`);
              this.conn.log({
                uid: this.user.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.user.username} has successfully deleted service #${id} - ${title}`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
              this.getServices();
              this.service = {};
              this.isNewService = true;
            } else {
              this.global.sweetToast('error', 'Sorry! Service was not deleted. Please try again');
            }
          });
        } else {
          this.global.sweetToast('info', 'Action canceled successfully');
        }
      });
    }
    
  }

  updateService() {
    if (!this.service.id) {
      this.global.sweetToast('error', 'Error: ID is missing');
    } else {
      const data = this.service;
      const id = this.service.id;
      delete data.id;
      this.conn
          .updateData(data, id, 'erp_service')
          .subscribe(async (res: any) => {
            if (res.data && res.data.affectedRows > 0) {
              if (res.data.changedRows > 0) {
                this.global.sweetToast('success', `Service ${id} was successfully updated`);
                this.getServices();
                this.conn.log({
                  uid: (this.user.uid ? this.user.uid : this.user.id),
                  createdAt: this.settingsService.currentDate(),
                  content: `Service was successfully updated by ${this.user.name}. Service ID #${id}`
                }).subscribe(log => {
                  // console.log('Log added successfully');
                });
              } else {
                this.global.sweetToast('warning', 'No changes was detected');
              }
            } else {
              this.global.sweetToast('error', 'Sorry! Service was not updated. Please try again');
            }
          });
    }
  }

}
