import { Component, OnInit, HostListener } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { from } from 'rxjs';
import { filter, toArray, map, delay, finalize } from 'rxjs/operators';
import { Client } from 'src/app/classes/client';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent implements OnInit {
  screenHeight;
  screenWidth;
  tableBodyHeight;
  user;
  isSearchingClient = false;
  idToSearch = 0;
  searchInput: any;
  services: any[];
  staffs: any[];
  customers: any[];
  clientsList: any[];
  record: any = {
    id: null,
    client_id: null,
    service_id: 60,
    description: '',
    created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    active: 1,
    owner_id: null,
    user_id: null,
    task_id: null,
    event_id: null
  };
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '80px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
            'redo',
            'link',
            'unlink',
            'video'
        ],
     ]
  };
  typeBtn = 'add';
  records: any[];
  searchInputRecord: any;
  allRecords: Array<object>;
  selectedRecordDate: any = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.tableBodyHeight = this.screenHeight - 252;
  }
  constructor(
    private settingsService: SettingsService,
    private conn: ConnectionService,
    private global: Globals,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
      this.record.user_id = this.user.uid;
      this.record.owner_id = this.user.uid;
    });
    this.getScreenSize();
    this.getRecords();
  }

  getServiceList() {
    this.conn
        .getServices()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(filter((service: any) => service.active == 1), toArray())
          .subscribe(servicesArr => this.services = servicesArr.sort((a, b) => (a.title > b.title) ? 1 : -1));
        });
  }

  getStaffList() {
    this.conn
        .getStaff()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((staffs: any) => ({
              id: staffs.id,
              name: staffs.name,
              access_status: staffs.access_status,
              level: this.settingsService.convertLevel(staffs.access_level),
            })),
            filter((staff: any) => staff.access_status === 'active'),
            toArray()
            )
          .subscribe(staffArr => this.staffs = staffArr.sort((a, b) => (a.name > b.name) ? 1 : -1));
        });
  }

  getCustomerList() {
    this.conn
        .getCustomers()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            map((client: any) => ({
              id: client.id,
              name: client.name,
              phone_primary: client.phone_primary,
              phone_secondary: client.phone_secondary,
              access_status: client.access_status
            })),
            filter((clientObj: any) => clientObj.access_status === 'active'),
            toArray()
          )
          .subscribe(clientArr => {
              const newArr = clientArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.customers = newArr;
          });
        });
  }

  getRecords() {
    this.getCustomerList();
    this.getServiceList();
    this.getStaffList();
    this.conn
        .getRecord()
        .subscribe((rcd: any) => {
          from(rcd.data)
            .pipe(
              delay(2000),
              finalize(() => {
                this.getCustomerList();
                this.getStaffList();
                this.getServiceList();
              }),
              filter((record: any) => record.active == 1),
              map((rec: any) => ({
                ...rec,
                clientObj: this.customers.find(customer => customer.id == rec.client_id),
                serviceObj: this.services.find(service => service.id == rec.service_id),
                ownerObj: this.staffs.find(staff => staff.id == rec.owner_id),
                createdBy: this.staffs.find(creator => creator.id == rec.user_id)
              })),
              toArray()
              )
            .pipe(
              map(x => x.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1 ))
            )
            .subscribe((records) => {
              this.allRecords = records;
              this.records = records;
              // console.log(this.allRecords);
              this.route.queryParams.subscribe(params => {
                const passedId: string = params['id'];
                if (passedId) {
                  from(this.allRecords)
                  .pipe(
                    filter((rec: any) => rec.id == passedId)
                  ).subscribe((res: any) => {
                    this.selectRecord(res);
                    this.location.replaceState('records')
                  });
                }
                
              });
              this.settingsService.spinner(false, 0);
            });
        });
  }

  formatSearchClientData(clients, isAlt?) {
    from(clients.data)
    .pipe(
        map((client: Client) => ({
          name: client.name,
          id: client.id,
          access_status: client.access_status
        })),
        filter((cl: Client) => cl.access_status === 'active'),
        toArray()
      )
      .subscribe((clientsList) => {
        if (clientsList.length) {
          this.clientsList = clientsList.sort((a, b) => (a.name > b.name) ? 1 : -1);
          if (isAlt) {
            this.clientsList.concat(clientsList).sort((a, b) => (a.name > b.name) ? 1 : -1);
          } 
          this.isSearchingClient = true;
        } else {
          this.isSearchingClient = false;
        }
      });
  }

  getSearchClient(event) {
    this.clientsList = [];
    const input = event.target.value;
    this.idToSearch = 0;

    if (!input) {
      this.isSearchingClient = false;
    } else {
      const isNumber = /^[0-9]*$/.test(input);
      if (isNumber) {
        this.conn
          .searchClient(input, 'phone_primary')
          .subscribe((clients: any) => {
              // console.log(clients.data.length);
              if (clients.data.length) {
                this.formatSearchClientData(clients);
              }
          });
            
        this.conn
          .searchClient(input, 'phone_secondary')
          .subscribe((alt: any) => {
            // console.log(alt.data.length);
            if (alt.data.length) {
              this.formatSearchClientData(alt, true);
            }
          });

      } else {
        this.conn
          .searchClient(input, 'name')
          .subscribe((clients: any) => {
            this.formatSearchClientData(clients);
        });
      }
      
    }

  }

  selectRecord(item) {

    this.selectedRecordDate = moment.utc(item.created_at).format('YYYY-MM-DD');
    
    this.typeBtn = 'update';
    if(item.clientObj) {
      this.searchInput = item.clientObj.name;
    } else {
      this.searchInput = '';
    }
    this.record = item;
  }

  search() {
    this.settingsService.searchTable('search', 'searchable');
  }

  setIdToSearch(id, name) {
    this.idToSearch = id;
    this.searchInput = name;
    this.isSearchingClient = false;
  }

  setTypeBtn(data) {
    if (data === 'add') {
      this.typeBtn = data;
      this.searchInput = '';
      this.record.id = null;
      this.record.created_at = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      this.record.user_id = this.user.uid;
      this.record.owner_id = this.user.uid;
      this.record.service_id = 60;
      this.record.client_id = null;
      this.record.description = '';
      this.record.created_at = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      delete this.record.createdBy;
    }
  }

  add() {
    if (this.record && this.record.owner_id && this.record.description) {
      this.record.client_id = this.idToSearch;
      this.record.created_at = `${moment.utc(this.record.created_at).format('YYYY-MM-DD')} ${moment.utc(new Date()).format('HH:mm:ss')}`;
      const record: any = this.record;
      delete record.id;
      delete record.clientObj;
      delete record.serviceObj;
      delete record.ownerObj;
      delete record.createdBy;
      
      this.conn.addData(this.record, 'erp_record').subscribe((res: any) => {
        this.settingsService.spinner(true, 0);
        if (res.data && res.data.affectedRows > 0) {
          if (res.data.insertId) {
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully added Record ID #${res.data.insertId}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
              this.conn.log({
                uid: this.user.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.user.username} has successfully added a record`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
          }
          this.getRecords();
          this.setTypeBtn('add');
          this.settingsService.spinner(false, 0);
        } else {
          this.settingsService.spinner(false, 0);
          this.global.sweetToast('error', 'Record was not added');
        }
      });
    } else {
      this.settingsService.spinner(false, 0);
      this.global.sweetToast('error', 'Record should have Client and Service at least');
    }
  }

  
  update() {
    const id = this.record.id;
    const record: any = this.record;
    record.client_id = (this.idToSearch) ? this.idToSearch : record.client_id;
    record.created_at = moment.utc((record.created_at) ? record.created_at : new Date()).format('YYYY-MM-DD HH:mm:ss');
    delete record.id;
    delete record.clientObj;
    delete record.serviceObj;
    delete record.ownerObj;
    delete record.createdBy;
    
    this.conn.updateData(record, id, 'erp_record').subscribe(async (res: any) => {
      this.settingsService.spinner(true, 0);
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.settingsService.spinner(false, 0);
          this.global.sweetToast('success', `Record ${id} was successfully updated`);
          this.getRecords();
          this.selectRecord(this.record);
          this.conn.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `Record was successfully updated by ${this.user.name}. Record ID #${id}`
          }).subscribe(log => {
            // console.log('Log added successfully');
          });
        } else {
          this.settingsService.spinner(false, 0);
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.settingsService.spinner(false, 0);
        this.global.sweetToast('error', 'Sorry! Record was not updated. Please try again');
      }
      
    });
  }
  

  delete() {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    const record = {
      active: 0
    };

    // console.log(this.record);
    

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        this.settingsService.spinner(true, 0);
        this.conn.deleteData(this.record.id, 'erp_record').subscribe(async (res: any) => {
          const recordId = this.record.id;
          if (res.data && (res.data.affectedRows > 0 || res.data.changedRows > 0)) {
            this.getRecords();
            this.setTypeBtn('add');
            this.settingsService.spinner(false, 1000);
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `Record was successfully deleted by ${this.user.name}. Record ID #${recordId}`
            }).subscribe(log => {
              // console.log('Log added successfully');
            });
          } else {
            this.global.sweetToast('warning', 'No changes was detected');
          }
        });
  
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });
  }
}

