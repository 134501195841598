import { Component, OnInit, ElementRef, ViewEncapsulation, Input} from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import * as $ from 'jquery';
import { Globals } from 'src/app/globals.component';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None


})
export class NotesComponent implements OnInit {

  @Input() public user: any = {};
  public notes: any;
  public note: any;
  public noteEditable: any;
  public contentEditable = false;
  public closeResult: string;
  public p = 1;
  public modal = false;



  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals) {}

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
      this.getNotes(res.uid);
    });

  }

  search() {
    this.settingsService.searchTable('search', 'searchable');
  }

  sort() {
    this.settingsService.sortTable('searchable');
  }

  getNotes(uid) {
    this.conn.getData(uid, 'erp_note').subscribe((n: any) => {
      this.notes = n.data;
      if (n.data && n.data.length) {
        this.note = n.data[0];
        this.noteEditable = n.data[0];
      }
      if (n.data) {
        this.settingsService.spinner(false, 100);
      }
    });
  }

  onChange(id) {
    const selected = this.notes.filter(note => note.id === +id);
    this.noteSelected(selected[0]);
  }

  noteSelected(item: any) {
    this.contentEditable = false;
    this.note = item;
    this.noteEditable = item;
  }

  isActive(el: ElementRef) {
    $('.item-notes').removeClass('link-active');
    $(el).addClass('link-active');
  }

  edit(item: any) {
    this.contentEditable = true;
    this.noteEditable = item;
  }

  delete(item: { id: any; }) {
    const note = item;
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        this.conn.deleteData(item.id, 'erp_note').subscribe((res: any) => {
          if (res.data && res.data.affectedRows > 0) {
            this.getNotes(this.user.uid);
            this.global.sweetToast('success', 'Note was successfully deleted');
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully deleted note #${note.id}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
            this.contentEditable = false;
          } else {
            this.global.sweetToast('error', 'Sorry! Note was not deleted. Please try again');
          }
        });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });

  }

  update() {
    const data = {
      subject: this.noteEditable.subject,
      content: this.noteEditable.content
    };
    this.conn.updateData(data, this.noteEditable.id, 'erp_note').subscribe((res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.global.sweetToast('success', 'Note was successfully updated');
          this.conn.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `User ${this.user.username} has successfully updated note #${this.noteEditable.id}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
          this.contentEditable = false;
        } else {
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.global.sweetToast('error', 'Sorry! Note was not updated. Please try again');
      }
    });
  }

  open() {
    if (this.modal) {
      this.modal = false;
    } else {
      this.modal = true;
    }
  }

  dismiss() {
    this.contentEditable = false;
  }

}
