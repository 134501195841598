// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://8aijkhe59i.execute-api.us-east-1.amazonaws.com/dev',
  key: 'Celksy010108',
  autoSuggestPlace: 'https://dev.virtualearth.net/REST/v1/Autosuggest',
  searchPlaceKey: 'ApXRm4GX9eA6kpY66jjcvyeEDL0s_xkFbxgbPXQ75ISvpk-niTcYK17ulKpXjiyz',
  s3BucketRegion: 'us-east-1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
