import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(value: Array<number | string>, args?: any): any {
    return value.sort((a, b) => a < b ? -1 : 1);
  }
}
