
import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { Staff } from 'src/app/classes/staff';
import {} from 'googlemaps';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userInfo = {} as any;
  currentlyPass = '' as any;
  user = {} as Staff;
  userFullAddress = '' as any;
  userAccessStatus = '' as any;
  userAccessLevel = '' as any;
  changePassword = false;
  passwordVisible = false;
  newPassword = '' as string;
  passMatch = false;
  retryPassword = '' as string;

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals) { }

  ngOnInit() {
    this.settingsService
        .checkLogin();

    this.settingsService
        .userInfo
        .subscribe((info: any) => {
          this.userInfo = info;
          this.userFullAddress = info.address_full;
          this.conn
              .findById(info.uid, 'erp_staff')
              .subscribe((res: any) => {
                this.user = res.data[0];
                this.currentlyPass = this.user.password;
                this.userAccessStatus = this.user.access_status;
                this.userAccessLevel = this.user.access_level;
              });
        });

  }

  updateUsername() {
    this.user.username = this.user.email;
  }

  searchPlace(input) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert(`No details available for input: ${place.name}`);
        return;
      }
      let address = '';
      if (place.address_components) {
        if (place.address_components[0] && (place.address_components[0].short_name || place.address_components[0].long_name)) {
          if (place.address_components[0].long_name) {
            address = place.address_components[0].long_name;
            this.user.address_number = place.address_components[0].long_name;
          } else {
            address = place.address_components[0].short_name;
            this.user.address_number = place.address_components[0].short_name;
          }
        }
        if (place.address_components[1] && (place.address_components[1].short_name || place.address_components[1].long_name)) {
          if (place.address_components[1].long_name) {
            address += ` ${place.address_components[1].long_name}`;
            this.user.address_street = place.address_components[1].long_name;
          } else {
            address += ` ${place.address_components[1].short_name}`;
            this.user.address_street = place.address_components[1].short_name;
          }
        }
        if (place.address_components[2] && (place.address_components[2].short_name || place.address_components[2].long_name)) {
          if (place.address_components[2].long_name) {
            address += `, ${place.address_components[2].long_name}`;
            this.user.address_city = place.address_components[2].long_name;
          } else {
            address += `, ${place.address_components[2].short_name}`;
            this.user.address_city = place.address_components[2].short_name;
          }
        }
        if (place.address_components[3] && (place.address_components[3].short_name || place.address_components[3].long_name)) {
          if (place.address_components[3].long_name) {
            address += `, ${place.address_components[3].long_name}`;
            this.user.address_county = place.address_components[3].long_name;
          } else {
            address += `, ${place.address_components[3].short_name}`;
            this.user.address_county = place.address_components[3].short_name;
          }
        }
        if (place.address_components[4] && (place.address_components[4].short_name || place.address_components[4].long_name)) {
          if (place.address_components[4].long_name) {
            address += `, ${place.address_components[4].short_name}`;
            this.user.address_state = place.address_components[4].short_name;
          } else {
            address += `, ${place.address_components[4].long_name}`;
            this.user.address_state = place.address_components[4].long_name;
          }
        }
        if (place.address_components[5] && (place.address_components[5].short_name || place.address_components[5].long_name)) {
          if (place.address_components[5].long_name) {
            address += `, ${place.address_components[5].short_name}`;
            this.user.address_country = place.address_components[5].short_name;
          } else {
            address += `, ${place.address_components[5].long_name}`;
            this.user.address_country = place.address_components[5].long_name;
          }
        }
        if (place.address_components[6] && (place.address_components[6].short_name || place.address_components[6].long_name)) {
          if (place.address_components[6].long_name) {
            address += ` ${place.address_components[6].short_name}`;
            this.user.address_zipcode = place.address_components[6].short_name;
          } else {
            address += ` ${place.address_components[6].long_name}`;
            this.user.address_zipcode = place.address_components[6].long_name;
          }
        }
        if (place.address_components[7] && (place.address_components[7].short_name || place.address_components[7].long_name)) {
          if (place.address_components[7].long_name) {
            address += `-${place.address_components[7].short_name}`;
          } else {
            address += `-${place.address_components[7].long_name}`;
          }
        }
      }
      this.userFullAddress = address;
    });
  }

  onSelect(event: { target: { checked: boolean; }; }) {
    this.changePassword = event.target.checked;
  }

  passwordVisibility() {
    if (this.passwordVisible) {
      this.passwordVisible = false;
    } else {
      this.passwordVisible = true;
    }
  }

  checkPassword() {
    if (this.retryPassword === this.newPassword) {
      this.passMatch = true;
    } else {
      this.passMatch = false;
    }
  }

  update() {
    const uid = this.user.id;
    const client  = this.user;
    delete client.id;
    delete client.created_at;
    delete client.updated_at;
    if (this.changePassword) {
      if (this.passMatch) {
        client.password = this.settingsService.encryptSHA256(this.newPassword);
        this.updateData(client, uid);
      } else {
        this.global.sweetToast('error', 'Sorry! User was not updated. Please try check if password is typed correctly');
      }
    } else {
      delete client.password;
      this.updateData(client, uid);
    }
  }

  updateData(data, id) {
    this.conn.updateData(data, id, 'erp_staff').subscribe(async (res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.global.sweetToast('success', `User ${this.user.name} was successfully updated`);
          this.conn.log({
            uid: id,
            createdAt: this.settingsService.currentDate(),
            content: `User ${this.user.name} was successfully updated. User ID #${id}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
        } else {
          this.global.sweetToast('warning', 'No changes were detected');
        }
      } else {
        this.global.sweetToast('warning', 'User was not updated! No changes were detected');

      }
    });
  }

}
