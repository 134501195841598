import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  // private user;

  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    // this.settingsService.userInfo.subscribe((res: any) => {
      // this.user = res;
    // });
  }

}
