import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { from, Observable, Observer } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { Client } from 'src/app/classes/client';
import { Globals } from 'src/app/globals.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ClientComponent } from './../../pages/client/client.component';




@Component({
  selector: 'app-modal-client',
  templateUrl: './modal-client.component.html',
  styleUrls: ['./modal-client.component.scss']
})
export class ModalClientComponent implements OnInit {

  newClient: any = {
    name: '',
    address_number: '',
    address_street: '',
    address_comp: '',
    address_city: '',
    address_county: '',
    address_state: '',
    address_country: '',
    address_zipcode: '',
    phone_primary: '',
    phone_secondary: '',
    email: '',
    team_id: '',
    company_id: '',
    can_signin: '',
    access_status: '',
    avatar: '',
    created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    updated_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    dob: '',
    doc: '',
    doc_type: '',
    note: '',
    customer_company: '',
    customer_company_doc: '',
    score: ''
  };
  newAddress: string;
  dtPickerOpt: object;
  listDocs: Array<object>;
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    imageEndPoint: '',
    toolbar: [
        [
            'bold',
            'italic',
            'underline',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'cut',
            'copy',
            'delete',
            'removeFormat',
            'undo',
            'redo',
            'link',
            'unlink',
            'video'
        ],
     ]
  };
  user: any;
  clientsList: any[];
  idToSearch = 0;
  isSearchingClient = false;

  isPhoneDuplicated = false;
  phoneRelated = '';

  places$: Observable<Array<object>>;

  customAddressToggle: boolean = false;

  constructor(
    private clientComponent: ClientComponent,
    private settingsService: SettingsService,
    private conn: ConnectionService,
    private global: Globals,
  ) { }

  ngOnInit() {
    this.dtPickerOpt = this.global.datePickerOptions;
    this.docs();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
  }

  dismiss() {
    this.clientComponent.toggleModal();
  }

  numbersOnly(event): boolean {
    return this.global.numberOnly(event);
  }

  add() {
    // console.log(this.newClient);
    if (this.newClient && (this.newClient.name !== '' && this.newClient.phone_primary !== '')) {
      if (this.newClient.doc_type === '') {
        this.newClient.doc_type = 1;
      }
      if (this.newClient.access_status === '') {
        this.newClient.access_status = 'active';
      }
      if (this.newClient.company_id === '') {
        this.newClient.company_id = this.user.company.id;
      }
      if (this.newClient.team_id === '') {
        this.newClient.team_id = this.user.team.id;
      }
      this.newClient.dob = moment.utc(this.newClient.dob).format('YYYY-MM-DD HH:mm:ss');
      this.conn.addData(this.newClient, 'erp_customer').subscribe((res: any) => {
        if (res.data && res.data.affectedRows > 0) {
          if (res.data.insertId) {
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully added client (${this.newClient.name}). ID #${res.data.insertId}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
              this.conn.log({
                uid: this.user.uid,
                createdAt: this.settingsService.currentDate(),
                content: `User ${this.user.username} has successfully added a client (${this.newClient.name})`
              }).subscribe(log => {
                // console.log('Login successfully');
              });
          }
          this.clientComponent.getClients();
          this.clientComponent.toggleModal();
          this.reset();
          this.global.sweetToast('success', `Client ${this.newClient.name} added successfully`);
        } else {
          this.global.sweetToast('error', 'Client was not added');
        }
      });
    } else {
      this.global.sweetToast('error', 'Client should have NAME and PHONE at least');
    }
  }

  toggleAddress(event) {
    // console.log(event);
    if (event.target.checked) {
      this.customAddressToggle = true;
    } else {
      this.customAddressToggle = false;
    }
    
  }

  selectPlace(item) {
    const iterator = item.address;
    this.newClient.address_number = iterator.address_number;
    this.newClient.address_street = iterator.address_street;
    this.newClient.address_city = iterator.address_city;
    this.newClient.address_county = iterator.address_county;
    this.newClient.address_state = iterator.address_state;
    this.newClient.address_country = iterator.address_country;
    this.newClient.address_zipcode = iterator.address_zipcode;
    this.newAddress = item.place;
    // console.log(this.newAddress, this.newClient, item);
    this.places$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
      observer.next([]);
    })
  }
  
  searchPlace(input) {
    const value = new String(input);
    if (value.length > 2) {
      this.searchPlaceBing(input);
    }
  }

  searchPlaceBing(input) {
    this.conn
        .searchPlace(input.value)
        .then((res: any) => {
          const arr = res.resourceSets[0].resources[0].value;
          const newArr = [];
          
          for (const item of arr) {
            const iterator = item.address;            
            newArr.push({
              place: iterator.formattedAddress,
              address: {
                address_number: iterator.houseNumber,
                address_street: iterator.streetName,
                address_city: iterator.locality,
                address_county: iterator.adminDistrict2,
                address_state: iterator.adminDistrict,
                address_country: iterator.countryRegionIso2,
                address_zipcode: iterator.postalCode
              }
            });
          }
          this.places$ = new Observable<Array<object>>((observer: Observer<Array<object>>) => {
            observer.next(newArr);
          })
        })
        .catch((err: HttpErrorResponse) => {
          console.log(err);
          this.searchPlaceGoogle(input);
          console.log("Getting results from Google");
          
        })
  }

  searchPlaceGoogle(input) {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert(`No details available for input: ${place.name}`);
        return;
      }
      let address = '';
      if (place.address_components) {
        if (place.address_components[0] && (place.address_components[0].short_name || place.address_components[0].long_name)) {
          if (place.address_components[0].long_name) {
            address = place.address_components[0].long_name;
            this.newClient.address_number = place.address_components[0].long_name;
          } else {
            address = place.address_components[0].short_name;
            this.newClient.address_number = place.address_components[0].short_name;
          }
        }
        if (place.address_components[1] && (place.address_components[1].short_name || place.address_components[1].long_name)) {
          if (place.address_components[1].long_name) {
            address += ` ${place.address_components[1].long_name}`;
            this.newClient.address_street = place.address_components[1].long_name;
          } else {
            address += ` ${place.address_components[1].short_name}`;
            this.newClient.address_street = place.address_components[1].short_name;
          }
        }
        if (place.address_components[2] && (place.address_components[2].short_name || place.address_components[2].long_name)) {
          if (place.address_components[2].long_name) {
            address += `, ${place.address_components[2].long_name}`;
            this.newClient.address_city = place.address_components[2].long_name;
          } else {
            address += `, ${place.address_components[2].short_name}`;
            this.newClient.address_city = place.address_components[2].short_name;
          }
        }
        if (place.address_components[3] && (place.address_components[3].short_name || place.address_components[3].long_name)) {
          if (place.address_components[3].long_name) {
            address += `, ${place.address_components[3].long_name}`;
            this.newClient.address_county = place.address_components[3].long_name;
          } else {
            address += `, ${place.address_components[3].short_name}`;
            this.newClient.address_county = place.address_components[3].short_name;
          }
        }
        if (place.address_components[4] && (place.address_components[4].short_name || place.address_components[4].long_name)) {
          if (place.address_components[4].long_name) {
            address += `, ${place.address_components[4].short_name}`;
            this.newClient.address_state = place.address_components[4].short_name;
          } else {
            address += `, ${place.address_components[4].long_name}`;
            this.newClient.address_state = place.address_components[4].long_name;
          }
        }
        if (place.address_components[5] && (place.address_components[5].short_name || place.address_components[5].long_name)) {
          if (place.address_components[5].long_name) {
            address += `, ${place.address_components[5].short_name}`;
            this.newClient.address_country = place.address_components[5].short_name;
          } else {
            address += `, ${place.address_components[5].long_name}`;
            this.newClient.address_country = place.address_components[5].long_name;
          }
        }
        if (place.address_components[6] && (place.address_components[6].short_name || place.address_components[6].long_name)) {
          if (place.address_components[6].long_name) {
            address += ` ${place.address_components[6].short_name}`;
            this.newClient.address_zipcode = place.address_components[6].short_name;
          } else {
            address += ` ${place.address_components[6].long_name}`;
            this.newClient.address_zipcode = place.address_components[6].long_name;
          }
        }
      }
      this.newAddress = address;

      // console.log(address);
      
    });
  }
  
  docs() {
    this.conn
        .getDocs()
        .subscribe((res: any) => {
          this.listDocs = res.data;
        });
  }

  getSearchClient(event) {
    this.clientsList = [];
    const input = event.target.value;
    this.idToSearch = 0;

    if (!input) {
      this.isSearchingClient = false;
    } else {
      const isNumber = /^[0-9]*$/.test(input);
      if (isNumber) {
        this.conn
          .searchClient(input, 'phone_primary')
          .subscribe((clients: any) => {
              // console.log(clients.data.length);
              if (clients.data.length) {
                this.formatSearchClientData(clients);
              }
          });
            
        this.conn
          .searchClient(input, 'phone_secondary')
          .subscribe((alt: any) => {
            // console.log(alt.data.length);
            if (alt.data.length) {
              this.formatSearchClientData(alt, true);
            }
          });

      } else {
        this.conn
          .searchClient(input, 'name')
          .subscribe((clients: any) => {
            this.formatSearchClientData(clients);
        });
      }
      
    }

  }

  fillForm(client) {
    this.isSearchingClient = false;
    this.conn
        .findById(client.id, 'erp_customer')
        .subscribe((res: any) => {
          this.newClient = res.data[0];
        });
  }

  checkDuplicates(event) {
    if (this.numbersOnly(event)) {
      const phone = event.target.value;
      const count = new String(phone);
      if (count.length > 9) {
        // console.log(event.target.value, count, count.length);
        this.conn
        .searchData(phone,'erp_customer','phone_primary')
        .subscribe((res: any) => {
          this.phoneRelated = '';
          if (res.data.length) {
            this.phoneRelated = res.data[0].name;
            this.isPhoneDuplicated = true;
          } else {
            this.isPhoneDuplicated = false;
          }
          // console.log(res.data);
        });
      } else {
        this.isPhoneDuplicated = false;
      }
    }
  }

  formatSearchClientData(clients, isAlt?) {
    from(clients.data)
    .pipe(
        map((client: Client) => ({
          name: client.name,
          id: client.id,
          access_status: client.access_status
        })),
        filter((cl: Client) => cl.access_status === 'active'),
        toArray()
      )
      .subscribe((clientsList) => {
        if (clientsList.length) {
          this.clientsList = clientsList.sort((a, b) => (a.name > b.name) ? 1 : -1);
          if (isAlt) {
            this.clientsList.concat(clientsList).sort((a, b) => (a.name > b.name) ? 1 : -1);
          } 
          this.isSearchingClient = true;
        } else {
          this.isSearchingClient = false;
        }
      });
  }

  reset() {
    this.isSearchingClient = false;
    this.newClient = {
      name: '',
      address_number: '',
      address_street: '',
      address_comp: '',
      address_city: '',
      address_county: '',
      address_state: '',
      address_country: '',
      address_zipcode: '',
      phone_primary: '',
      phone_secondary: '',
      email: '',
      team_id: '',
      company_id: '',
      can_signin: '',
      access_status: '',
      avatar: '',
      created_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      updated_at: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
      dob: '',
      doc: '',
      doc_type: '',
      note: '',
      customer_company: '',
      customer_company_doc: '',
      score: ''
    };
  }

}
