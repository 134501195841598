import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Globals } from 'src/app/globals.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  path;
  public user?: any;
  public menu: Array<object> = [
    {icon: 'home', label: 'Dashboard', level: 1, link: '/dashboard'},
    {icon: 'people', label: 'Clients', level: 1, link: '/client'},
    // {icon: 'chat', label: 'Chat', level: 1, link: '/chat'},
    // {icon: 'envelope-closed', label: 'E-mail', level: 1, link: '/email'},
    {icon: 'justify-left', label: 'Notes', level: 1, link: '/notes'},
    {icon: 'clipboard', label: 'Records', level: 1, link: '/records'},
    {icon: 'calendar', label: 'Calendar', level: 1, link: '/calendar'},
    {icon: 'graph', label: 'Finance', level: 1, link: '/finance'},
    {icon: 'file', label: 'Files', level: 1, link: '/files'},
    {icon: 'key', label: 'Admin', level: 4, link: '/admin'},
    {icon: 'globe', label: 'Website', level: 4, link: '/website'},
    {icon: 'puzzle-piece', label: 'Settings', level: 5, link: '/settings'},
  ];

  constructor(private settings: SettingsService,
              private global: Globals) { }

  ngOnInit() {
    this.settings.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.path = this.global.path;
  }

  logout() {
    this.settings.logout(this.user);
  }

}
