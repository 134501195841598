import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SigninComponent } from './pages/signin/signin.component';
import { NotesComponent } from './pages/notes/notes.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ChatComponent } from './pages/chat/chat.component';
import { ClientComponent } from './pages/client/client.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { RecordsComponent } from './pages/records/records.component';
import { WebsiteComponent } from './pages/website/website.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { FileComponent } from './pages/file/file.component';
import { ClientsComponent } from './pages/file/clients/clients.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: 'client', component: ClientComponent },
  { path: 'notes', component: NotesComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'records', component: RecordsComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'finance', component: FinanceComponent },
  { path: 'website', component: WebsiteComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'files', component: FileComponent },
  { path: 'archive/:id', component: ClientsComponent },
  { path: '**', component: SigninComponent },
  { path: '',   redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
