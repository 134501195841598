import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { CalendarComponent } from 'src/app/pages/calendar/calendar.component';
import * as moment from 'moment';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss']
})
export class ModalCalendarComponent implements OnInit, AfterViewInit {

  @Input() type: string;
  @Input() modal: any;
  @Input() user: any;
  @Input() status: boolean;

  public showUpdate: boolean;
  public updateEventData: any;
  public startDate = moment.utc(new Date()).format('MM/DD/YYYY - h:mm a');
  public endDate = moment.utc(new Date()).format('MM/DD/YYYY - h:mm a');
  public allDayString;
  public activeEventString;
  public showStart: boolean;
  public showEnd: boolean
  public data: any;

  constructor(private calendar: CalendarComponent,
              private conn: ConnectionService,
              private global: Globals,
              private settingsService: SettingsService
              ) {}

  ngOnInit() {
    this.type = this.calendar.modalType;
    this.status = this.calendar.modalStatus;
    this.modal = this.calendar.modalElem;
    this.user = this.calendar.user;
    this.data = this.calendar.modalElemData;
    this.showStart = false;
    this.showEnd = false;
    this.updateEventData = {
      id: null,
      end: new Date(),
      start: new Date(),
      title: '',
      groupId: '',
      allDay: false,
      active: 'true',
      uid: this.user.uid
    };
  }

  ngAfterViewInit() {
    if (this.status) {
      if (this.type === 'add') {
        this.updateEventData = {
          id: null,
          end: new Date(),
          start: new Date(),
          title: '',
          groupId: '',
          allDay: false,
          active: 'true',
          uid: this.user.uid
        };
        this.showUpdate = false;
        this.startDate = moment.utc(new Date()).format('MM/DD/YYYY - h:mm a');
        this.endDate = moment.utc(new Date()).format('MM/DD/YYYY - h:mm a');
      } else {
        this.data = this.calendar.modalElemData;
        this.updateEventData = {
          id: this.data.event.id,
          end: this.data.event.end,
          start: this.data.event.start,
          title: this.data.event.title,
          groupId: this.data.event.groupId,
          allDay: this.data.event.allDay,
          active: this.data.event.extendedProps.active,
          uid: this.data.event.extendedProps.uid
        };
        this.showUpdate = true;
        this.startDate = moment.utc(this.data.event.start).format('MM/DD/YYYY - h:mm a');
        this.endDate = moment.utc(this.data.event.end).format('MM/DD/YYYY - h:mm a');
      }
    }
  }

  allDayCheck(value) {
    if (value || value === 'true' || value === 1) {
      return true;
    } else {
      return false;
    }
  }

  onSelectAllDay(event) {
    if (event && event.target.value === 'true') {
      this.showStart = false;
      this.showEnd = false;
    }
  }

  activeCheck(value) {
    if (value || value === 'true' || value === 1) {
      return true;
    } else {
      return false;
    }
  }

  showHideStart() {
    if (this.showStart) {
      this.showStart = false;
    } else {
      this.showStart = true;
    }
  }

  showHideEnd() {
    if (this.showEnd) {
      this.showEnd = false;
    } else {
      this.showEnd = true;
    }
  }

  changeStartDate(event) {
    this.startDate = moment.utc(event.value).format('MM/DD/YYYY - h:mm a');
    this.updateEventData.start = event.value;
  }

  changeEndDate(event) {
    this.endDate = moment.utc(event.value).format('MM/DD/YYYY - h:mm a');
    this.updateEventData.end = event.value;
  }

  @HostListener('change') onChangeStart(event) {
    if (event) {
      this.changeStartDate(event);
      this.showHideStart();
    }
  }

  @HostListener('change') onChangeEnd(event) {
    if (event) {
      this.changeEndDate(event);
      this.showHideEnd();
    }
  }

  @HostListener('blur') onBlurStart(event) {
    if (event) {
      this.changeStartDate(event);
      this.showHideStart();
    }
  }

  @HostListener('blur') onBlurEnd(event) {
    if (event) {
      this.changeEndDate(event);
      this.showHideEnd();
    }
  }

  add() {
    if (this.updateEventData) {
      if (this.updateEventData.title !== '') {
        if (this.updateEventData.start !== '' || this.updateEventData.allDay) {
          const data = {
            id: this.updateEventData.id,
            endAt: moment.utc(this.updateEventData.end).format('YYYY-MM-DD HH:mm:ss'),
            startAt: moment.utc(this.updateEventData.start).format('YYYY-MM-DD HH:mm:ss'),
            title: this.updateEventData.title,
            groupId: this.updateEventData.groupId,
            allDay: this.updateEventData.allDay,
            active: this.updateEventData.active,
            uid: this.updateEventData.uid
          };
          this.conn.addData(data, 'erp_calendar').subscribe((res: any) => {
            if (res.data && res.data.affectedRows > 0) {
              if (res.data.insertId) {
                this.conn.log({
                  uid: this.user.uid,
                  createdAt: this.settingsService.currentDate(),
                  content: `User ${this.user.username} has successfully added event #${res.data.insertId}`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              } else {
                  this.conn.log({
                    uid: this.user.uid,
                    createdAt: this.settingsService.currentDate(),
                    content: `User ${this.user.username} has successfully added a event`
                  }).subscribe(log => {
                    // console.log('Login successfully');
                  });
              }
              this.calendar.renderEvents(this.user.uid);
              this.modal.closeModal();
              this.global.sweetToast('success', 'Event added successfully');
            } else {
              this.global.sweetToast('error', 'Event was not added');
            }
          });
        } else {
          this.global.sweetToast('error', 'Event must have a start and end date');
        }
      } else {
        this.global.sweetToast('error', 'Event must have a title');
      }
    } else {
      this.global.sweetToast('error', 'Event was not setup correctly');
    }

  }

  delete() {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };

    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        this.conn.deleteData(this.updateEventData.id, 'erp_calendar').subscribe((res: any) => {
          if (res.data && res.data.affectedRows > 0) {
            this.calendar.renderEvents(this.user.uid);
            this.calendar.modalStatus = false;
            this.global.sweetToast('success', 'Event was successfully deleted');
            this.modal.closeModal();
            this.conn.log({
              uid: this.user.uid,
              createdAt: this.settingsService.currentDate(),
              content: `User ${this.user.username} has successfully deleted event #${this.updateEventData.id}`
            }).subscribe(log => {
              // console.log('Login successfully');
            });
          } else {
            this.global.sweetToast('error', 'Sorry! Event was not deleted. Please try again');
          }
        });
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });

  }

  dismiss() {
    this.status = false;
    this.calendar.modalStatus = false;
  }

  update() {
    const data = {
      id: this.updateEventData.id,
      endAt: moment.utc(this.updateEventData.end).format('YYYY-MM-DD HH:mm:ss'),
      startAt: moment.utc(this.updateEventData.start).format('YYYY-MM-DD HH:mm:ss'),
      title: this.updateEventData.title,
      groupId: this.updateEventData.groupId,
      allDay: this.updateEventData.allDay,
      active: this.updateEventData.active,
      uid: this.updateEventData.uid
    };
    this.conn.updateData(data, data.id, 'erp_calendar').subscribe((res: any) => {
      if (res.data && res.data.affectedRows > 0) {
        if (res.data.changedRows > 0) {
          this.calendar.renderEvents(this.user.uid);
          this.calendar.modalStatus = false;
          this.global.sweetToast('success', 'Event was successfully updated');
          this.showUpdate = false;
          this.modal.closeModal();
          this.conn.log({
            uid: this.user.uid,
            createdAt: this.settingsService.currentDate(),
            content: `User ${this.user.username} has successfully updated event #${data.id}`
          }).subscribe(log => {
            // console.log('Login successfully');
          });
        } else {
          this.global.sweetToast('warning', 'No changes was detected');
        }
      } else {
        this.global.sweetToast('error', 'Sorry! Event was not updated. Please try again');
      }
    });
  }

}
