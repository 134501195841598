import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Info } from 'src/app/classes/info';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { UserRecover } from 'src/app/classes/staff';
import * as $ from 'jquery';
import { toast } from '../../../../node_modules/materialize-css';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  public showRecover = false;
  public userEmail = '';
  private format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  public info: Info;
  public showMessageErrorText = '';
  public submitBtn = false;

// tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private settingsService: SettingsService, private conn: ConnectionService, private nav: Router) { }

  ngOnInit() {
    this.settingsService.getInfo().subscribe(res => {
      this.info = res.data[0];
    });


    this.route.queryParams.subscribe(res => {
      if (res.token && res.token !== '') {
        if (this.validateToken(res.token)) {
          if (res.oauth && this.validateOauth(res.oauth)) {
            if (res.email && res.email !== '') {
              this.conn.checkEmail(res.email).subscribe((resp: UserRecover) => {
                if (resp.responseType === 'success' && resp.authorized && resp.data.length > 0) {
                  this.userEmail = resp.data[0].email;
                  this.showRecover = true;
                }
              });
            }
          }
        }
      }
    });
  }

  toastFunc(text: string, length: number) {
    toast({
      html: text,
      displayLength: length,
      inDuration: 300,
      outDuration: 375,
    });
  }

  checkPassword() {
    const pw = $('#pw').val();
    const rpw = $('#rpw').val();

    if (pw === rpw) {
      this.showMessageErrorText = '';
      this.submitBtn = true;

    } else {
      this.showMessageErrorText = 'Password does not match!';
      this.toastFunc(this.showMessageErrorText, 4000);
      this.submitBtn = false;

    }
  }

  onSubmit(f: { value: { password: any; }; status: string; }) {
    if (this.submitBtn && f.status === 'VALID') {
      const encryptedPass = this.settingsService.encryptSHA256(f.value.password);
      this.conn.updateSignInStaff(this.userEmail, encryptedPass).subscribe((res: any) => {
        // console.log(res);
        if (res.data && res.data.affectedRows > 0) {
          if (res.data.changedRows === 0) {
            this.showMessageErrorText = 'Error: Password must be different from previous';
            this.toastFunc(this.showMessageErrorText, 4000);
          } else {
            this.showMessageErrorText = 'Success: Password updated you will be redirected';
            this.toastFunc(this.showMessageErrorText, 4000);
            this.nav.navigate(['']);
            this.showRecover = false;
          }
        } else {
          this.showMessageErrorText = 'Error: Couldn\'t update user\'s password';
          this.toastFunc(this.showMessageErrorText, 6000);
        }
      });
    } else {
      this.showMessageErrorText = 'Error: Couldn\'t update user\'s password';
      this.toastFunc(this.showMessageErrorText, 6000);
    }
  }

  private validateToken(token: string) {

    let result = false;
    if (!this.format.test(token)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  private validateOauth(oauth: string) {
    let result = false;
    if (!this.format.test(oauth)) {
      if (oauth === '4da9687473aa6374acadc90924287a3809397d10dfc073fa22c739f2ac72e5aa') {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  }

}
