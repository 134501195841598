import { Component, OnInit, HostListener } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { Client } from 'src/app/classes/client';
import { from, Observable } from 'rxjs';
import { filter, map, toArray, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  user;
  clientId;
  screenWidth;
  pager: any = {};
  pagedItems: any[];
  customers;
  selectedClient;
  files: any[];
  fileTotal;
  fileLoading;
  filePercentage;
  addTitle: '';
  addDescription: '';
  fileList$: Observable<any[]>;
  

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  selectedFiles: FileList;

  constructor(
    private settingsService: SettingsService,
    private conn: ConnectionService,
    private global: Globals,
    private pagination: PaginationService,
    private route: ActivatedRoute,
    private router: Router) {
      this.getScreenSize();
  }

  ngOnInit() {
    this.settingsService.spinner(true, 0);
    // this.settingsService.checkCookie();
    this.settingsService.checkLogin();
    this.settingsService.userInfo.subscribe((res: any) => {
      this.user = res;
    });
    this.getCustomerList();
    
  }

  setPage(page: number) {
    let pageSize = 16;
    let pageQuantity = 10;
    if (this.screenWidth < 425) {
      pageSize = 4;
      pageQuantity = 1;
    } else if (this.screenWidth < 768) {
      pageSize = 6;
      pageQuantity = 2;
    } else if (this.screenWidth < 1024) {
      pageSize = 9;
      pageQuantity = 5;
    } else if (this.screenWidth < 1200) {
      pageSize = 9;
      pageQuantity = 8;
    } else if (this.screenWidth < 1440) {
      pageSize = 12;
      pageQuantity = 10;
    } else {
      pageSize = 16;
      pageQuantity = 10;
    }

    const newArr = this.customers;

    // get pager object from service
    this.pager = this.pagination.getPager(newArr.length, page, pageSize, pageQuantity);

    // get current page of items
    this.pagedItems = newArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // console.log(this.pagedItems);
    
  }

  getCustomerList() {
    this.conn
        .getCustomers()
        .subscribe((response: any) => {
          from(response.data)
          .pipe(
            filter((clientObj: Client) => clientObj.access_status === 'active'),
            map((client: Client) => ({
              id: client.id,
              name: client.name,
              access_status: client.access_status,
              phone_primary: client.phone_primary,
              phone_secondary: client.phone_secondary,
              email: client.email
            })),
            toArray()
          )
          .subscribe(clientArr => {
              const newArr = clientArr.sort((a, b) => (a.name > b.name) ? 1 : -1);
              this.customers = newArr;
              let id = this.route.paramMap.pipe(
                switchMap((params: ParamMap) => params.getAll('id'))
              );
              id.subscribe(respId => {
                this.clientId = Number(respId);
                this.getFilesList();
                from(this.customers)
                .pipe(
                  filter((selected: Client) => selected.id == Number(respId))
                ).subscribe(respClient => {
                  this.selectedClient = respClient;
                  // console.log(respClient);
                  this.settingsService.spinner(false);
                })
              })
              
              // this.setPage(1);
          });
        });
  }

  upload() {
    this.filePercentage = '';
    this.fileTotal = '';
    this.fileLoading = '';
    const file = this.selectedFiles.item(0);
    const bucket = this.conn.S3Conn();
    const contentType = file.type;
    const params = {
        Bucket: 'bth-erp-files',
        Key: file.name,
        Body: file,
        ACL: 'private',
        ContentType: contentType
    };
    const _self = this;
    bucket.upload(params).on('httpUploadProgress', function (evt) {
        // console.log(evt);
        // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
        _self.fileTotal = evt.total;
        _self.fileLoading = evt.loaded;
        _self.filePercentage = Number((evt.loaded / evt.total)*100).toFixed(2);
        console.log(_self.fileLoading, _self.fileTotal, _self.filePercentage);
        
    }).send(function (err, data) {
        if (err) {
            console.log('There was an error uploading your file: ', err);
            return false;
        }
        // console.log('Successfully uploaded file.', data);
        var params = {Bucket: data.Bucket, Key: data.Key};
        var url = bucket.getSignedUrl('getObject', params);
        // console.log('The URL is', url);
        if (data.Key && data.Bucket) {
          let file = {
            id: 'NULL',
            client_id: _self.clientId,
            url: url,
            title: _self.addTitle,
            created_at: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            size: _self.fileTotal,
            type: '', // Should be the file ext.
            description: _self.addDescription,
            bucket: data.Bucket,
            key: data.Key,
            active: 1
          }
          // console.log(file);
          
          if (!file.title) {
            file.title = "";
          }
          if (!file.description) {
            file.description = "";
          }
          // console.log(file,data);
          _self.conn.addDataValues(file, 'erp_file').subscribe((res: any) => {
            _self.settingsService.spinner(true, 0);
            // console.log(res);
            
            if (res.data && res.data.affectedRows > 0) {
              _self.getFilesList();
              _self.addTitle = '';
              _self.addDescription = '';
              _self.filePercentage = '';
              _self.fileTotal = '';
              _self.fileLoading = '';
              _self.global.sweetToast('success', 'File added successfully');
              if (res.data.insertId) {
                _self.conn.log({
                  uid: _self.user.uid,
                  createdAt: _self.settingsService.currentDate(),
                  content: `User ${_self.user.username} has successfully added files ID #${res.data.insertId}`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              } else {
                  _self.conn.log({
                    uid: _self.user.uid,
                    createdAt: _self.settingsService.currentDate(),
                    content: `User ${_self.user.username} has successfully added a file ${data.Key}`
                  }).subscribe(log => {
                    // console.log('Login successfully');
                  });
              }
              _self.settingsService.spinner(false, 0);
            } else {
              _self.settingsService.spinner(false, 0);
              _self.global.sweetToast('error', 'File was not added');
            }
          });
        }
        return true;
    });

  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    // console.log(this.selectedFiles);
  }

  getFilesList() {
    this.conn.getFiles()
    .subscribe((res: any) => {
      this.fileList$ = from(res.data)
      .pipe(
        filter((file: any) => file.client_id == this.clientId),
        toArray()
      )
    })
  }

  delete(item) {
    const data = {
      title: 'Attention',
      text: 'This will be permanently deleted. Are you sure about this action?',
      type: 'warning',
      cancelButton: true,
      confirmText: 'Delete it!'
    };
    
    this.global.sweetAlert(data).then((result: { value: any; }) => {
      if (result.value) {
        const _self = this;
        _self.settingsService.spinner(true, 0);
        // console.log(item);
        const bucket = this.conn.S3Conn();
        const params = {
          Bucket: item.bucket,
          Key: item.key
        }
        bucket.deleteObject(params, function(err, data) {
          if (err) {
            console.log(err);
            _self.global.sweetToast('error', 'Sorry! Event was not deleted. Please try again');
          } else {
            console.log("Successfully deleted on S3");
            _self.conn.deleteData(item.id, 'erp_file').subscribe((res: any) => {
              if (res.data && res.data.affectedRows > 0) {
                _self.getFilesList();
                _self.global.sweetToast('success', 'File was successfully deleted');
                _self.conn.log({
                  uid: _self.user.uid,
                  createdAt: _self.settingsService.currentDate(),
                  content: `User ${_self.user.username} has successfully deleted file #${item.id} - ${item.title} from bucket ${item.bucket}/${item.key}`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              } else {
                _self.global.sweetToast('error', 'Sorry! file was not deleted. Please try again');
              }
            });
    
          }
        })
        _self.settingsService.spinner(false, 1000);
      } else {
        this.global.sweetToast('info', 'Action canceled successfully');
      }
    });

    
    
  }

  generateUrl(bucket, key) {
    const params = {Bucket: bucket, Key: key};
    const conn = this.conn.S3Conn();
    const url = conn.getSignedUrl('getObject', params);
    
    if(url) {
      window.open(url, 'new')
    } else {
      this.global.sweetToast('error', 'Sorry! We could not find this file');
    }
  }

  searchFiles() {
    this.settingsService.searchTable('search', 'searchable');
  }

  sort() {
    this.settingsService.sortTable('searchable');
  }

}
