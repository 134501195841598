import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { Globals } from 'src/app/globals.component';
import { NotesComponent } from './../../pages/notes/notes.component';
import { AddNote } from 'src/app/classes/note';

@Component({
  selector: 'app-modal-notes',
  templateUrl: './modal-notes.component.html',
  styleUrls: ['./modal-notes.component.scss']
})
export class ModalNotesComponent implements OnInit {

  public newNote?: AddNote;
  public user: any;

  constructor(private settingsService: SettingsService,
              private conn: ConnectionService,
              private global: Globals,
              private notesComponent: NotesComponent) { }

  ngOnInit() {
    this.newNote = {
      subject: '',
      content: ''
    };
    this.user = this.notesComponent.user;
  }

  dismiss() {
    this.notesComponent.open();
  }

  add() {
    if (this.newNote) {
      if (this.newNote.subject !== '') {
        if (this.newNote.content !== '') {
          const note = {
            uid: this.user.uid,
            subject: this.newNote.subject,
            content: this.newNote.content,
            updatedAt: this.settingsService.currentDate()
          };
          this.conn.addData(note, 'erp_note').subscribe((res: any) => {
            if (res.data && res.data.affectedRows > 0) {
              if (res.data.insertId) {
                this.conn.log({
                  uid: this.user.uid,
                  createdAt: this.settingsService.currentDate(),
                  content: `User ${this.user.username} has successfully added note #${res.data.insertId}`
                }).subscribe(log => {
                  // console.log('Login successfully');
                });
              } else {
                  this.conn.log({
                    uid: this.user.uid,
                    createdAt: this.settingsService.currentDate(),
                    content: `User ${this.user.username} has successfully added a note`
                  }).subscribe(log => {
                    // console.log('Login successfully');
                  });
              }
              this.notesComponent.getNotes(this.user.uid);
              this.notesComponent.modal = false;
              this.global.sweetToast('success', 'Note added successfully');
            } else {
              this.global.sweetToast('error', 'Note was not added');
            }
          });
        } else {
          this.global.sweetToast('error', 'Note must have a content');
        }
      } else {
        this.global.sweetToast('error', 'Note must have a title');
      }
    } else {
      this.global.sweetToast('error', 'Note was not setup correctly');
    }
  }
}
